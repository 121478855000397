import React from "react";

import { Page, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import Topic from "./Topic";
import styles from "./styles";

export default function Topics({ i }) {
  return (
    <Page size="A4" style={{ padding: 20 }}>
      <View style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {sortByKeys(i.incident.incidentTopics, "ordering")
            .slice(2)
            .map((topic) => (
              <Topic key={topic.id} topic={topic} i={i} />
            ))}
        </View>
      </View>
    </Page>
  );
}

Topics.propTypes = {
  i: PropTypes.object,
};
