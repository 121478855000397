import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
  FormControlLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { GET_COMPANIES } from "../../../../../graphql/queries";
import { CustomSwitch } from "../../../../CustomComponents/Switch";

export default function TableFilterDialog({
  open,
  setOpen,
  onActiveChange,
  active,
  onSiteOwnerChange,
  siteOwner,
  clearFilters,
}) {
  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent style={{ minHeight: "100px" }}>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Typography>Show Inactive?</Typography>
            <FormControlLabel
              style={{ color: "black" }}
              control={
                <CustomSwitch
                  checked={active}
                  onChange={onActiveChange}
                  value={true}
                />
              }
              label={active ? "YES" : "NO"}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="project-filter-siteowner"
              options={
                data
                  ? data.companies.toSorted((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                  : []
              }
              getOptionLabel={(option) => option.name}
              value={siteOwner}
              onChange={onSiteOwnerChange}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site Owner"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onActiveChange: PropTypes.func.isRequired,
  active: PropTypes.object.isRequired,
  onSiteOwnerChange: PropTypes.func.isRequired,
  siteOwner: PropTypes.object.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
