import React from "react";

import { InfoOutlined } from "@mui/icons-material";
import { Button, Grid, Typography, Tooltip } from "@mui/material";

import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { USER_PERMISSION_GROUPS } from "../../../../../../graphql/mutations/admin/permissions";
import { GET_COMPANY_USER_DATA } from "../../../../../../graphql/queries/admin/company";
import { GET_USERS_ADMIN } from "../../../../../../graphql/queries/admin/user";
import { GET_PERMISSION_GROUPS } from "../../../../../../graphql/queries/permissions";
import sortByGetFunction from "../../../../../../utils/sortByGetFunction";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../../CustomComponents/SelectedUnselectedAdmin";

export default function EditUserDialog({ setOpen, d, disabled }) {
  // Queries
  const { data: groups } = useQuery(GET_PERMISSION_GROUPS);

  // Permission Group Mutation
  const [mutateUserPermissionGroup] = useMutation(USER_PERMISSION_GROUPS, {
    onCompleted() {
      onCompletedFunc("User Permissions Groups have been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_COMPANY_USER_DATA,
        variables: { id: Number(d.user.id) },
      },
      { query: GET_USERS_ADMIN },
    ],
  });

  // Permissions State
  const [selectedPermissions, setSelectedPermissions] = React.useState([]);
  const [unselectedPermissions, setUnselectedPermissions] = React.useState([]);

  // Set selected permissions if info updates
  React.useEffect(() => {
    if (d) {
      setSelectedPermissions(d.user.userPermissionGroups);
    }
  }, [d]);

  // Set unselected Permissions when query updates
  React.useEffect(() => {
    if (groups && d.user.userPermissionGroups) {
      const filtered = groups.PermissionGroups.filter(
        (p) =>
          p.isActive &&
          d.user.userPermissionGroups.findIndex(
            (perm) => perm.permissionGroup.id === p.id
          ) < 0
      );
      setUnselectedPermissions(filtered);
    }
  }, [groups, d]);

  // On Done: closes modal and resets
  const onDone = () => {
    setOpen(false);
  };

  // On Save: chooses send mutation
  const onSave = () => {
    mutateUserPermissionGroup({
      variables: {
        id: d.user ? Number(d.user.id) : Number(d.id),
        apg: selectedPermissions.map((p) =>
          p.permissionGroup ? Number(p.permissionGroup.id) : Number(p.id)
        ),
        dpg: unselectedPermissions.map((p) =>
          p.permissionGroup ? Number(p.permissionGroup.id) : Number(p.id)
        ),
      },
    });
  };

  // unselected and selected item format different
  // Could use a css face lift
  const permissionInfoIcon = (item) => {
    let descriptions = [];
    if (item.permissionGroupPermissions) {
      descriptions = item.permissionGroupPermissions;
    } else {
      descriptions = item.permissionGroup.permissionGroupPermissions;
    }
    return descriptions && descriptions.length > 0
      ? descriptions.map((item) => item.permission.description).join(" ")
      : "";
  };

  const renderDisplayText = (item) => {
    return (
      <>
        <Typography
          style={{
            marginRight: "5px",
            color: "black",
            display: "inline",
            maxWidth: "85%",
          }}
        >
          {item.name ? item.name : item.permissionGroup.name}
        </Typography>
        <Tooltip title={permissionInfoIcon(item)}>
          <InfoOutlined sx={{ verticalAlign: "top" }} />
        </Tooltip>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <Grid sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
          disabled={disabled}
        >
          SAVE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <SelectedUnselected
            selected={selectedPermissions}
            unselected={unselectedPermissions}
            setSelected={setSelectedPermissions}
            setUnselected={setUnselectedPermissions}
            updateState={() => {}}
            idKey={"id"}
            label={"Permission Groups"}
            sortFunction={(arr) =>
              sortByGetFunction(arr, (item) =>
                item.name ? item.name : item.permissionGroup.name
              )
            }
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.name ? item.name : item.permissionGroup.name;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {renderButtons()}
    </>
  );
}

EditUserDialog.propTypes = {
  setOpen: PropTypes.func,
  d: PropTypes.object,
  disabled: PropTypes.bool,
};
