import React, { memo } from "react";
import { Virtuoso } from "react-virtuoso";

import { Check, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Checkbox, Grid, IconButton, Typography } from "@mui/material";

import _ from "lodash";

import sortByGetFunction from "../../../../../utils/sortByGetFunction";
import sortByKeys from "../../../../../utils/sortByKeys";
import SearchField from "../../../../CustomComponents/SearchField";

export const UserAccordion = memo(function UserAccordion({
  value,
  siteUsers,
  setSiteUsers,
  addedUsers,
  setAddedUsers,
  setRemovedUsers,
  users,
  supervisors,
  setSupervisors,
  disabled,
}) {
  const [search, setSearch] = React.useState("");
  const [searchAdded, setSearchAdded] = React.useState("");

  const addUser = (u) => {
    setAddedUsers((prev) => [...prev, u]);
    setRemovedUsers((prev) =>
      prev.filter((prev) => prev.id === u.id || prev.user?.id === u.user?.id)
    );
  };

  const removeUser = (u) => {
    setAddedUsers((prev) => prev.filter((prev) => prev.id !== u.id));
    if (
      siteUsers.findIndex((siteUser) => siteUser.user.id === u.user?.id) >= 0
    ) {
      setSiteUsers((prev) => prev.filter((prev) => prev.user.id !== u.user.id));
      setSupervisors((prev) => prev.filter((s) => s !== Number(u.user.id)));
      setRemovedUsers((prev) => [...prev, u]);
    }
  };

  const handleChangeSupervisor = (event, id) => {
    if (event.target.checked) {
      setSupervisors((prev) => [...prev, Number(id)]);
    } else {
      setSupervisors((prev) => prev.filter((s) => s !== Number(id)));
    }
  };

  return (
    <>
      {value === 0 ? (
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={6}>
            <Typography>ADDED USERS:</Typography>
            <SearchField value={searchAdded} setValue={setSearchAdded} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {siteUsers.length === 0 && addedUsers.length === 0 && (
                <Typography style={{ padding: "20px", color: "black" }}>
                  No added users.
                </Typography>
              )}
              {_.filter(siteUsers, function (user) {
                return `${user.user.firstName}${user.user.lastName}${user.user.username}`
                  .toLowerCase()
                  .includes(searchAdded.toLowerCase().trim());
              }).map((u) => (
                <Grid
                  item
                  xs={12}
                  key={u.id}
                  style={{
                    padding: "10px",
                    height: "fit-content",
                  }}
                  container
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <IconButton
                      style={{ marginRight: "10px" }}
                      onClick={() => removeUser(u)}
                      disabled={disabled}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                  <Grid item xs={7} container>
                    <Typography
                      style={{
                        marginRight: "15px",
                        color: "black",
                        width: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      {u.user.firstName !== ""
                        ? `${u.user.firstName} ${u.user.lastName} (${u.user.username})`
                        : u.user.username}{" "}
                      [{u.user.company?.name}]
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Checkbox
                      checked={
                        supervisors.findIndex((s) => s === Number(u.user.id)) >=
                        0
                      }
                      onChange={(event) =>
                        handleChangeSupervisor(event, u.user.id)
                      }
                      disabled={disabled}
                    />
                    <Typography style={{ fontSize: "12px" }}>
                      Is Supervisor?
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              {_.filter(addedUsers, function (user) {
                return `${user.firstName}${user.lastName}${user.username}`
                  .toLowerCase()
                  .includes(searchAdded.toLowerCase().trim());
              }).map((u) => (
                <Grid
                  item
                  xs={12}
                  key={u.id}
                  style={{
                    padding: "10px",
                    height: "fit-content",
                  }}
                  container
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <IconButton
                      style={{ marginRight: "10px" }}
                      onClick={() => removeUser(u)}
                      disabled={disabled}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                  <Grid item xs={7} container>
                    <Typography
                      style={{
                        marginRight: "15px",
                        color: "black",
                        width: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      {u.firstName !== ""
                        ? `${u.firstName} ${u.lastName} (${u.username})`
                        : u.username}{" "}
                      [{u.company?.name}]
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Checkbox
                      checked={
                        supervisors.findIndex((s) => s === Number(u.id)) >= 0
                      }
                      onChange={(event) => handleChangeSupervisor(event, u.id)}
                      disabled={disabled}
                    />
                    <Typography style={{ fontSize: "12px" }}>
                      Is Supervisor?
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography>UNADDED USERS:</Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {users && (
                <Virtuoso
                  style={{ height: 295, width: "100%" }}
                  data={sortByKeys(
                    _.filter(
                      _.filter(users.users, function (user) {
                        return (
                          addedUsers.findIndex((u) => u.id === user.id) < 0 &&
                          siteUsers.findIndex((u) => u.user.id === user.id) < 0
                        );
                      }),
                      function (user) {
                        return (
                          user.firstName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          user.lastName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          user.username
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      }
                    ),
                    "username"
                  )}
                  itemContent={(index, u) => (
                    <Grid
                      item
                      xs={12}
                      key={u.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => addUser(u)}
                          disabled={disabled}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          style={{
                            color: "black",
                            width: "100%",
                            wordWrap: "break-word",
                          }}
                        >
                          {u.firstName !== ""
                            ? `${u.firstName} ${u.lastName} (${u.username})`
                            : u.username}{" "}
                          [{u.company?.name}]
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

export const ContractorAccordion = memo(function ContractorAccordion({
  value,
  siteContractors,
  setSiteContractors,
  addedContractors,
  setAddedContractors,
  setRemovedContractors,
  contractors,
  disabled,
}) {
  const [search, setSearch] = React.useState("");

  const addContractor = (c) => {
    setAddedContractors((prev) => [...prev, c]);
    setRemovedContractors((prev) =>
      prev.filter((prev) => prev.id === c.id || prev.contractor?.id === c.id)
    );
  };

  const removeContractor = (c) => {
    setAddedContractors((prev) => prev.filter((prev) => prev.id !== c.id));

    if (
      c.contractor &&
      siteContractors.findIndex(
        (siteContractor) => siteContractor.contractor.id === c.contractor.id
      ) >= 0
    ) {
      setSiteContractors((prev) =>
        prev.filter((prev) => prev.contractor.id !== c.contractor.id)
      );
      setRemovedContractors((prev) => [...prev, c]);
    }
  };

  return (
    <>
      {value === 1 ? (
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={7}>
            <Typography>ADDED CONTRACTORS:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "58px", // because no search field
                borderRadius: 5,
                border: "1px solid #8297A0",
              }}
            >
              {siteContractors.length === 0 &&
                addedContractors.length === 0 && (
                  <Typography style={{ padding: "20px", color: "black" }}>
                    No added contractors.
                  </Typography>
                )}

              {sortByGetFunction(
                [...siteContractors, ...addedContractors],
                (item) => (item.contractor ? item.contractor.name : item.name)
              ).map((u) =>
                u.contractor ? (
                  <Grid
                    item
                    xs={12}
                    key={u.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => removeContractor(u)}
                        disabled={disabled}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {u.contractor.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    key={u.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => removeContractor(u)}
                        disabled={disabled}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {u.name}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography>UNADDED CONTRACTORS:</Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {contractors && (
                <Virtuoso
                  style={{ height: 295, width: "100%" }}
                  data={sortByKeys(
                    _.filter(
                      _.filter(contractors.companies, function (company) {
                        return (
                          siteContractors.findIndex(
                            (c) => c.contractor.id === company.id
                          ) < 0 &&
                          addedContractors.findIndex(
                            (c) => c.id === company.id
                          ) < 0
                        );
                      }),
                      function (c) {
                        return c.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      }
                    ),
                    "name"
                  )}
                  itemContent={(index, c) => (
                    <Grid
                      item
                      xs={12}
                      key={c.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => addContractor(c)}
                          disabled={disabled}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "5px", color: "black" }}
                        >
                          {c.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

export const CrewAccordion = memo(function CrewAccordion({
  value,
  addedCrew,
  setAddedCrew,
  siteCrew,
  setSiteCrew,
  setRemovedCrew,
  crew,
  disabled,
}) {
  const [search, setSearch] = React.useState("");

  const [searchAdded, setSearchAdded] = React.useState("");

  const addCrew = (c) => {
    setAddedCrew((prev) => [...prev, c]);
    setRemovedCrew((prev) => prev.filter((prev) => prev.id === c.id));
  };

  const removeCrew = (c) => {
    setAddedCrew((prev) => prev.filter((prev) => prev.id !== c.id));
    if (siteCrew.findIndex((crew) => crew.id === c.id) >= 0) {
      setSiteCrew((prev) => prev.filter((prev) => prev.id !== c.id));
      setRemovedCrew((prev) => [...prev, c]);
    }
  };

  return (
    <>
      {value === 2 ? (
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={7}>
            <Typography>ADDED CREWMEMBERS:</Typography>
            <SearchField value={searchAdded} setValue={setSearchAdded} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {siteCrew.length === 0 && addedCrew.length === 0 && (
                <Typography style={{ padding: "20px", color: "black" }}>
                  No added crewmembers.
                </Typography>
              )}

              {_.filter(siteCrew, function (crew) {
                return `${crew.crewMember.firstName}${crew.crewMember.lastName}`
                  .toLowerCase()
                  .includes(searchAdded.toLowerCase().trim());
              }).map((c) => (
                <Grid
                  item
                  xs={12}
                  key={c.id}
                  style={{
                    padding: "10px",
                    height: "fit-content",
                  }}
                  container
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <IconButton
                      style={{ marginRight: "10px" }}
                      onClick={() => removeCrew(c)}
                      disabled={disabled}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} container>
                    <Typography style={{ marginRight: "15px", color: "black" }}>
                      {c.crewMember.firstName} {c.crewMember.lastName}{" "}
                      {c.crewMember.company
                        ? `[${c.crewMember.company.name}]`
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              {_.filter(addedCrew, function (crew) {
                return `${crew.firstName}${crew.lastName}`
                  .toLowerCase()
                  .includes(searchAdded.toLowerCase().trim());
              }).map((c) => (
                <Grid
                  item
                  xs={12}
                  key={c.id}
                  style={{
                    padding: "10px",
                    height: "fit-content",
                  }}
                  container
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <IconButton
                      style={{ marginRight: "10px" }}
                      onClick={() => removeCrew(c)}
                      disabled={disabled}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} container>
                    <Typography style={{ marginRight: "15px", color: "black" }}>
                      {c.firstName} {c.lastName}{" "}
                      {c.company ? `[${c.company.name}]` : null}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography>UNADDED CREWMEMBERS:</Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {crew && (
                <Virtuoso
                  style={{ height: 295, width: "100%" }}
                  data={sortByGetFunction(
                    _.filter(
                      _.filter(crew.crewMembers, function (crew) {
                        return (
                          siteCrew.findIndex(
                            (c) => c.crewMember.id === crew.id
                          ) < 0 &&
                          addedCrew.findIndex((c) => c.id === crew.id) < 0
                        );
                      }),
                      function (crew) {
                        return `${crew.firstName}${crew.lastName}`
                          .toLowerCase()
                          .includes(search.toLowerCase().trim());
                      }
                    ),
                    (item) => `${item.firstName}${item.lastName}`.toLowerCase()
                  )}
                  itemContent={(index, c) => {
                    return (
                      <Grid
                        key={c.id}
                        style={{
                          padding: "10px",
                          height: "60px",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton
                            style={{ marginRight: "10px" }}
                            onClick={() => addCrew(c)}
                            disabled={disabled}
                          >
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10} container>
                          <Typography
                            style={{ marginRight: "5px", color: "black" }}
                          >
                            {c.firstName} {c.lastName}{" "}
                            {c.company ? `[${c.company.name}]` : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

export const TemplateAccordion = memo(function TemplateAccordion({
  value,
  addedTemplates,
  setAddedTemplates,
  siteTemplates,
  setSiteTemplates,
  setRemovedTemplates,
  templates,
  disabled,
}) {
  const [search, setSearch] = React.useState("");

  const addTemplate = (t) => {
    setAddedTemplates((prev) => [...prev, t]);
    setRemovedTemplates((prev) =>
      prev.filter((prev) => prev.id === t.id || prev.template?.id === t.id)
    );
  };

  const removeTemplate = (t) => {
    setAddedTemplates((prev) => prev.filter((prev) => prev.id !== t.id));
    if (
      siteTemplates.findIndex((siteTemp) => siteTemp.template.id === t.id) >= 0
    ) {
      setSiteTemplates((prev) =>
        prev.filter((prev) => prev.template.id !== t.id)
      );
      setRemovedTemplates((prev) => [...prev, t]);
    }
  };

  return (
    <>
      {value === 3 ? (
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={7}>
            <Typography>ADDED TEMPLATES:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "58px", // because no search field
                borderRadius: 5,
                border: "1px solid #8297A0",
              }}
            >
              {siteTemplates.length === 0 && addedTemplates.length === 0 && (
                <Typography style={{ padding: "20px", color: "black" }}>
                  No added templates.
                </Typography>
              )}

              {sortByGetFunction(
                [...siteTemplates, ...addedTemplates],
                (item) => (item.template ? item.template.name : item.name)
              ).map((t) =>
                t.template ? (
                  <Grid
                    item
                    xs={12}
                    key={t.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => removeTemplate(t.template)}
                        disabled={disabled}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {t.template.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    key={t.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => removeTemplate(t)}
                        disabled={disabled}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {t.name}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography>UNADDED TEMPLATES:</Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
                border: "1px solid #8297A0",
                margin: "5px 0px",
              }}
            >
              {templates && (
                <Virtuoso
                  style={{ height: 295, width: "100%" }}
                  data={sortByKeys(
                    _.filter(
                      _.filter(templates.templates, function (temp) {
                        return (
                          addedTemplates.findIndex((t) => t.id === temp.id) <
                            0 &&
                          siteTemplates.findIndex(
                            (t) => t.template.id === temp.id
                          ) < 0
                        );
                      }),
                      function (temp) {
                        return temp.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      }
                    ),
                    "name"
                  )}
                  itemContent={(index, t) => (
                    <Grid
                      item
                      xs={12}
                      key={t.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => addTemplate(t)}
                          disabled={disabled}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "5px", color: "black" }}
                        >
                          {t.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});
