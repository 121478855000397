import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Grid, TextField, Autocomplete, Checkbox } from "@mui/material";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { onlineVar } from "../../../graphql/localVariables/user";
import { haMutations } from "../../../graphql/mutations";
import {
  GET_FILTERED_PROJECTS_HA,
  GET_OFFLINE_OSP,
  GET_OSP_ID,
  GET_SITE_OSP,
  GET_ONLINE_PROJECTS_HA,
} from "../../../graphql/queries/ha";
import sortByKeys from "../../../utils/sortByKeys";
import { useStyles } from "./styles";

export default function ProjectInputView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const online = useReactiveVar(onlineVar);
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const [
    getOnlineProjects,
    { data: onlineProjects, loading: loadingProjects },
  ] = useLazyQuery(GET_ONLINE_PROJECTS_HA);

  const [getProjects, { data, loading }] = useLazyQuery(
    GET_FILTERED_PROJECTS_HA
  );
  const [getOSP] = useLazyQuery(GET_OSP_ID, {
    onCompleted(data) {
      updateHAFunction(
        "ospIds",
        data.ownerSiteProjects.map((osp) => Number(osp.id))
      );
    },
  });

  const [getSiteOSP] = useLazyQuery(GET_SITE_OSP, {
    onCompleted(data) {
      if (data && data.ownerSiteProjects && data.ownerSiteProjects[0]) {
        updateHAFunction("siteOSPId", Number(data.ownerSiteProjects[0].id));
      }
    },
  });

  const [getOfflineOSP] = useLazyQuery(GET_OFFLINE_OSP, {
    onCompleted(data) {
      console.log("Project Offline OSP", data);
      updateHAFunction(
        "ospIds",
        data.filterOSP.map((osp) => Number(osp.id))
      );
    },
  });
  const classes = useStyles()();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  React.useEffect(() => {
    if (updateHA.ownerId && updateHA.siteId) {
      if (online) {
        getOnlineProjects({
          variables: {
            ownerId: Number(updateHA.ownerId),
            siteId: Number(updateHA.siteId),
          },
        });
      } else {
        getProjects({
          variables: { ownerId: updateHA.ownerId, siteId: updateHA.siteId },
        });
      }
      getSiteOSP({
        variables: {
          ownerId: Number(updateHA.ownerId),
          siteId: Number(updateHA.siteId),
        },
      });
      updateHAFunction("ospIds", []);
    }
  }, [updateHA.ownerId, updateHA.siteId]);

  React.useEffect(() => {
    if (updateHA.projects && updateHA.projects.length !== 0) {
      if (online) {
        getOSP({
          variables: {
            ownerId: Number(updateHA.ownerId),
            siteId: Number(updateHA.siteId),
            projectIds: updateHA.projects.map((val) =>
              val.project ? Number(val.project.id) : Number(val.id)
            ),
          },
        });
      } else {
        getOfflineOSP({
          variables: {
            ownerId: Number(updateHA.ownerId),
            siteId: Number(updateHA.siteId),
            projectIds: updateHA.projects.map((val) =>
              val.project ? Number(val.project.id) : Number(val.id)
            ),
          },
        });
      }
    }
  }, [updateHA.projects]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      updateHAFunction("projects", newValue);
    } else {
      updateHAFunction("projects", []);
    }
  };

  const getProjectOptions = () => {
    if (online) {
      return onlineProjects
        ? sortByKeys(onlineProjects.ownerSiteProjects, "project.name")
        : [];
    }
    return data ? sortByKeys(data.filterProjects, "project.name") : [];
  };

  return (
    <Grid
      item
      key={"project"}
      xs={12}
      sm={6}
      lg={3}
      className={classes.borderedBox}
    >
      <Autocomplete
        disablePortal
        id="ha-select-project"
        options={getProjectOptions()}
        value={updateHA.projects || []}
        multiple
        onChange={handleChange}
        getOptionLabel={(option) =>
          option.project && option.project.name
            ? option.project.name
            : option.name
            ? option.name
            : ""
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.project && option.project.name
              ? option.project.name
              : option.name
              ? option.name
              : ""}
          </li>
        )}
        disabled={!updateHA.siteId}
        loading={online ? loadingProjects : loading}
        isOptionEqualToValue={(option, value) => {
          const optionId = option.project ? option.project.id : option.id;
          const valueId = value.project ? value.project.id : value.id;

          return value.name === "" ? true : optionId == valueId;
        }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={"Projects:"} />
        )}
      />
    </Grid>
  );
}
