import React from "react";

import { Grid, Paper, Typography } from "@mui/material";

export default function MobilePDFWarning() {
  return (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );
}
