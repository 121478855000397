import React from "react";

import { ExpandMore, FilterList } from "@mui/icons-material";
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  Menu,
  FormControlLabel,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { MUTATE_INDUSTRY } from "../../../graphql/mutations/admin/industry";
import {
  GET_ACTIVE_INDUSTRIES,
  GET_ALL_INDUSTRIES,
} from "../../../graphql/queries/admin/company";
import sortByKeys from "../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { CustomTextField } from "../../CustomStyles/LightTextField";

function OneIndustry({ industry, canEdit }) {
  const [editIndustry] = useMutation(MUTATE_INDUSTRY, {
    onCompleted() {
      onCompletedFunc("Industry has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_ACTIVE_INDUSTRIES },
      { query: GET_ALL_INDUSTRIES },
    ],
  });
  const [name, setName] = React.useState(industry.name);
  const [code, setCode] = React.useState(industry.code);
  const [isActive, setIsActive] = React.useState(industry.isActive);

  const onSave = () => {
    editIndustry({
      variables: { id: Number(industry.id), name, code, isActive },
    });
  };

  const onCancel = () => {
    setName(industry.name);
  };

  return (
    <Accordion
      style={{ width: "100%" }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="industry-content"
        id="industry-header"
        style={{
          backgroundColor: isActive ? "white" : "#BDBDBD",
        }}
      >
        {industry.name}
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: isActive ? "white" : "#BDBDBD",
        }}
      >
        <Grid container alignItems="center">
          <TextField
            variant="standard"
            label="Industry Name:"
            value={name}
            onChange={(event) => setName(event.target.value)}
            disabled={!canEdit}
          />
          <FormControlLabel
            style={{ color: "black", marginLeft: "20px" }}
            control={
              <CustomSwitch
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
                disabled={!canEdit}
              />
            }
            label={isActive ? "ACTIVE" : "INACTIVE"}
          />
        </Grid>
        <TextField
          variant="standard"
          label="Industry Code:"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          inputProps={{ maxLength: 6 }}
          disabled={!canEdit}
        />
        {canEdit && (
          <Grid item xs={12} style={{ margin: "20px 0px" }}>
            <Button
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={onSave}
            >
              SAVE
            </Button>
            <Button variant="contained" onClick={onCancel}>
              CANCEL
            </Button>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default function Industry({ industries, canEdit }) {
  const [search, setSearch] = React.useState("");
  const [showInActive, setShowInActive] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center">
        <CustomTextField
          label="SEARCH:"
          variant="standard"
          style={{ margin: "10px 0px" }}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <FilterList style={{ color: "white" }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <Grid style={{ padding: "20px" }}>
            <Typography>Show Inactive?</Typography>
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={showInActive}
                  onChange={(event) => setShowInActive(event.target.checked)}
                />
              }
              label={showInActive ? "YES" : "NO"}
            />
          </Grid>
        </Menu>
      </Grid>
      {sortByKeys(
        industries.filter((i) =>
          i.industry.name.toLowerCase().includes(search.toLowerCase()) &&
          showInActive
            ? true
            : i.isActive
        ),
        "industry.name"
      ).map((i) => (
        <OneIndustry
          key={i.industry.id}
          industry={i.industry}
          canEdit={canEdit}
        />
      ))}
    </Grid>
  );
}
