import React, { memo } from "react";

import { Check, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import _ from "lodash";

import sortByGetFunction from "../../../utils/sortByGetFunction";
import sortByKeys from "../../../utils/sortByKeys";
import SearchField from "../../CustomComponents/SearchField";

export const UserAccordion = memo(function UserAccordion({
  value,
  companyUsers,
  setCompanyUsers,
  addedUsers,
  setAddedUsers,
  setRemovedUsers,
  users,
  supervisors,
  setSupervisors,
  loadingUsers,
  loadingCompanyAdminUsers,
}) {
  const [search, setSearch] = React.useState("");

  const addUser = (u) => {
    setAddedUsers((prev) => [...prev, u]);
    setRemovedUsers((prev) =>
      prev.filter((prev) => prev.id === u.id || prev.user?.id === u.user?.id)
    );
  };

  const removeUser = (u) => {
    setAddedUsers((prev) => prev.filter((prev) => prev.id !== u.id));
    if (companyUsers.findIndex((siteUser) => siteUser.user.id !== u.id) >= 0) {
      setCompanyUsers((prev) => prev.filter((prev) => prev.user.id !== u.id));
      setRemovedUsers((prev) => [...prev, u]);
    }
  };

  const AddedUser = ({ u, handleChangeSupervisor }) => {
    const [sup, setSup] = React.useState(
      supervisors.findIndex((s) => s === Number(u.id)) >= 0
    );

    const handleChange = (event, id) => {
      setSup(event.target.value);
      handleChangeSupervisor(event, id);
    };

    return (
      <Grid
        item
        xs={12}
        key={u.id}
        style={{
          padding: "10px",
          height: "fit-content",
        }}
        container
        alignItems="center"
      >
        <Grid item xs={2}>
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={() => removeUser(u)}
          >
            <Check />
          </IconButton>
        </Grid>
        <Grid item xs={7} container>
          <Typography style={{ marginRight: "15px", color: "black" }}>
            {u.firstName !== ""
              ? `${u.firstName} ${u.lastName} (${u.username})`
              : u.username}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={sup}
            onChange={(event) => handleChange(event, u.id)}
          />
          <Typography style={{ fontSize: "12px" }}>Is Supervisor?</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleChangeSupervisor = (event, id) => {
    if (event.target.checked) {
      setSupervisors((prev) => [...prev, Number(id)]);
    } else {
      setSupervisors((prev) => prev.filter((s) => s !== Number(id)));
    }
  };

  return (
    <>
      {value === 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography style={{ color: "black" }}>ADDED USERS:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                marginTop: "10px",
                border: "1px solid #8297a0",
                borderRadius: "4px",
              }}
            >
              {loadingCompanyAdminUsers ? (
                <Grid container justifyContent="center" sx={{ my: 2 }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {companyUsers.length === 0 && addedUsers.length === 0 && (
                    <Typography style={{ padding: "20px", color: "black" }}>
                      No added users.
                    </Typography>
                  )}

                  {companyUsers
                    .sort((a, b) => {
                      if (
                        supervisors.includes(Number(a.id)) &&
                        !supervisors.includes(Number(b.id))
                      )
                        return -1;

                      if (
                        (supervisors.includes(Number(a.id)) &&
                          supervisors.includes(Number(b.id))) ||
                        (!supervisors.includes(Number(a.id)) &&
                          !supervisors.includes(Number(b.id)))
                      )
                        return a.username.toLowerCase() >
                          b.username.toLowerCase()
                          ? 1
                          : -1;

                      return 1;
                    })
                    .map((u) => (
                      <AddedUser
                        key={u.id}
                        u={u}
                        handleChangeSupervisor={handleChangeSupervisor}
                      />
                    ))}

                  {addedUsers.map((u) => (
                    <Grid
                      item
                      xs={12}
                      key={u.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => removeUser(u)}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={7} container>
                        <Typography
                          style={{ marginRight: "15px", color: "black" }}
                        >
                          {u.firstName !== ""
                            ? `${u.firstName} ${u.lastName} (${u.username})`
                            : u.username}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={
                            supervisors.findIndex((s) => s === Number(u.id)) >=
                            0
                          }
                          onChange={(event) =>
                            handleChangeSupervisor(event, u.id)
                          }
                        />
                        <Typography style={{ fontSize: "12px" }}>
                          Is Supervisor?
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ color: "black" }}>UNADDED USERS:</Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "250px",
                overflowY: "auto",
                width: "100%",
                marginTop: "10px",
                border: "1px solid #8297a0",
                borderRadius: "4px",
              }}
            >
              {loadingUsers ? (
                <Grid container justifyContent="center" sx={{ my: 2 }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {users &&
                    sortByKeys(
                      _.filter(
                        _.filter(users.users, function (user) {
                          return (
                            addedUsers.findIndex((u) => u.id === user.id) < 0 &&
                            companyUsers.findIndex((u) => u.id === user.id) < 0
                          );
                        }),
                        function (user) {
                          return `${user.firstName}${user.lastName}${user.username}`
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        }
                      ),
                      "username"
                    ).map((u) => (
                      <Grid
                        item
                        xs={12}
                        key={u.id}
                        style={{
                          padding: "10px",
                          height: "fit-content",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton onClick={() => addUser(u)}>
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography style={{ color: "black", width: "100%" }}>
                            {u.firstName !== ""
                              ? `${u.firstName} ${u.lastName} (${u.username})`
                              : u.username}{" "}
                            [{u.company?.name}]
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

export const TemplateAccordion = memo(function TemplateAccordion({
  value,
  companyTemplates,
  setCompanyTemplates,
  addedTemplates,
  setAddedTemplates,
  setRemovedTemplates,
  templates,
  loadingTemplates,
  loadingCompanyAdminTemplates,
}) {
  const [search, setSearch] = React.useState("");

  const addTemplate = (t) => {
    setAddedTemplates((prev) => [...prev, t]);
    setRemovedTemplates((prev) =>
      prev.filter((prev) => prev.id === t.id || prev.template?.id === t.id)
    );
  };

  const removeTemplate = (t) => {
    setAddedTemplates((prev) => prev.filter((prev) => prev.id !== t.id));
    if (
      companyTemplates.findIndex((siteTemp) => siteTemp.template.id !== t.id) >=
      0
    ) {
      setCompanyTemplates((prev) =>
        prev.filter((prev) => prev.template.id !== t.id)
      );
      setRemovedTemplates((prev) => [...prev, t]);
    }
  };

  return (
    <>
      {value === 1 ? (
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <Typography style={{ color: "black" }}>ADDED TEMPLATES:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "10px",
                border: "1px solid #8297a0",
                borderRadius: "4px",
              }}
            >
              {loadingCompanyAdminTemplates ? (
                <Grid container justifyContent="center" sx={{ my: 2 }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {companyTemplates.length === 0 &&
                    addedTemplates.length === 0 && (
                      <Typography style={{ padding: "20px", color: "black" }}>
                        No added templates.
                      </Typography>
                    )}
                  {sortByGetFunction(
                    [...companyTemplates, ...addedTemplates],
                    (item) => (item.template ? item.template.name : item.name)
                  ).map((t) =>
                    t.template ? (
                      <Grid
                        item
                        xs={12}
                        key={t.id}
                        style={{
                          padding: "10px",
                          height: "fit-content",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton
                            style={{ marginRight: "10px" }}
                            onClick={() => removeTemplate(t.template)}
                          >
                            <Check />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10} container>
                          <Typography
                            style={{ marginRight: "15px", color: "black" }}
                          >
                            {t.template.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        key={t.id}
                        style={{
                          padding: "10px",
                          height: "fit-content",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton
                            style={{ marginRight: "10px" }}
                            onClick={() => removeTemplate(t)}
                          >
                            <Check />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10} container>
                          <Typography
                            style={{ marginRight: "15px", color: "black" }}
                          >
                            {t.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ color: "black" }}>
              UNADDED TEMPLATES:
            </Typography>
            <SearchField value={search} setValue={setSearch} dark />
            <Grid
              container
              style={{
                maxHeight: "250px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                border: "1px solid #8297a0",
                borderRadius: "4px",
              }}
            >
              {loadingTemplates ? (
                <Grid container justifyContent="center" sx={{ my: 2 }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {templates &&
                    sortByKeys(
                      _.filter(
                        _.filter(templates.templates, function (temp) {
                          return (
                            addedTemplates.findIndex((t) => t.id === temp.id) <
                              0 &&
                            companyTemplates.findIndex(
                              (t) => t.id === temp.id
                            ) < 0
                          );
                        }),
                        function (temp) {
                          return temp.name
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        }
                      ),
                      "name"
                    ).map((t) => (
                      <Grid
                        item
                        xs={12}
                        key={t.id}
                        style={{
                          padding: "10px",
                          height: "fit-content",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton
                            style={{ marginRight: "10px" }}
                            onClick={() => addTemplate(t)}
                          >
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10} container>
                          <Typography
                            style={{ marginRight: "5px", color: "black" }}
                          >
                            {t.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});
