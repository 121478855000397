import { gql } from "@apollo/client";

// VIEW

export const GET_HAS = gql`
  query getHAs(
    $first: Int
    $after: String
    $owner: ID
    $site: ID
    $projects: [ID]
    $templates: [ID]
    $submitterCompany: ID
    $name: String
    $status: Boolean
    $incident: Boolean
    $user: [ID]
    $afterDate: Date
    $beforeDate: Date
  ) {
    hazardAnalyses(
      isActive: true
      first: $first
      after: $after
      fOwner: $owner
      fSite: $site
      fProjects: $projects
      fTemplates: $templates
      fSubmitterCompany: $submitterCompany
      fName: $name
      fUser: $user
      fStatus: $status
      fIncident: $incident
      fAfterDate: $afterDate
      fBeforeDate: $beforeDate
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          isCompleted
          name
          id
          haId
          revisionNumber
          comment
          haOwnerSiteProject {
            isActive
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
              project {
                id
                name
              }
            }
          }
          haTemplate {
            template {
              name
            }
          }
          submitter {
            id
            username
            company {
              id
              name
            }
          }
          haCrewMembers(isActive: true) {
            id
            crewMember {
              id
              firstName
              lastName
              jobTitle {
                id
                name
              }
              phoneNumber
              lastUpdated
            }
          }
          observation {
            edges {
              node {
                obsId
              }
            }
          }
          date
          time
          raScore
          hasInjury
          isCompleted
        }
      }
    }
  }
`;

export const VIEW_HA = gql`
  query getHA($id: Int!) {
    hazardAnalysis(id: $id) {
      name
      id
      haId
      date
      time
      timeCreated
      lastUpdated
      isCompleted
      timeCompleted
      weather
      revisionNumber
      raScore
      raScoreMax
      raScoreMin
      grade
      submitter {
        username
        firstName
        lastName
        company {
          id
          name
          logoBase64
        }
        supervisedBy {
          isActive
          supervisor {
            id
            username
            firstName
            lastName
          }
        }
      }
      haOwnerSiteProjects(isActive: true) {
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      comment
      hasInjury
      injuryText
      haCrewMembers(isActive: true) {
        id
        crewMember {
          id
          firstName
          lastName
          signatureBase64
          lastUpdated
        }
      }
      haTemplate {
        id
        template {
          id
          name
        }
      }
      haArms(isActive: true, arm_IsActive: true) {
        actionOrdering
        arm {
          id
          riskScore
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query getProjects {
    projects(isActive: true) {
      name
      id
      ownerSiteProject {
        owner {
          id
        }
        site {
          id
        }
      }
    }
  }
`;

export const GET_ONLINE_PROJECTS_HA = gql`
  query onlineProjects($ownerId: Int!, $siteId: Int!) {
    ownerSiteProjects(
      isActive: true
      owner_Id: $ownerId
      site_Id: $siteId
      project_Isnull: false
      project_IsActive: true
    ) {
      site {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

// OFFLINE

export const GET_FILTERED_PROJECTS_HA = gql`
  query filteredProjects($ownerId: String!, $siteId: String!) {
    filterProjects(ownerId: $ownerId, siteId: $siteId) @client {
      site {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

export const GET_OFFLINE_FILTERED_CREWMEMBERS = gql`
  query offlineHACrewmembers($ospId: Int) {
    filterCrewMembers(ospId: $ospId) @client {
      crewMember {
        id
        firstName
        lastName
        signatureBase64
        phoneNumber
        lastUpdated
      }
    }
  }
`;

export const GET_OFFLINE_OSP = gql`
  query offlineOSP($ownerId: String, $siteId: String, $projectIds: [String]) {
    filterOSP(ownerId: $ownerId, siteId: $siteId, projectIds: $projectIds)
      @client {
      id
    }
  }
`;

export const GET_OFFLINE_HA_TEMPLATES = gql`
  query offlineHATemplates($ospIdList: [Int]) {
    filterHATemplates(ospIdList: $ospIdList) @client {
      ownerSiteProject {
        id
      }
      template {
        id
        name
        startDate
        endDate
        templateArmIds(isActive: true)
      }
    }
  }
`;

export const GET_OFFLINE_TEMPLATE = gql`
  query offlineTemplate($ids: [Int]!) {
    filterTemplate(idList: $ids) {
      id
      name
      templateArms(isActive: true, arm_IsActive: true) {
        arm {
          id
          riskScore
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
    }
  }
`;

// CREATE/EDIT
export const GET_SEARCH_TEMPLATES = gql`
  query getTemplatesSearch {
    templates(isActive: true) {
      id
      name
    }
  }
`;

export const GET_TEMPLATE = gql`
  query getTemplate($ids: [Int]!) {
    templates(isActive: true, id_In: $ids) {
      id
      name
      templateArms(isActive: true, arm_IsActive: true) {
        arm {
          id
          riskScore
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_HA_TEMPLATES = gql`
  query HATemplates($ospIdList: [Int]) {
    ownerSiteProjectTemplatesHa(
      ownerSiteProject_Id_In: $ospIdList
      isActive: true
      template_IsActive: true
    ) {
      ownerSiteProject {
        id
      }
      template {
        id
        name
        startDate
        endDate
      }
    }
  }
`;

export const GET_OSP_ID = gql`
  query ownerSiteProject($ownerId: Int!, $siteId: Int!, $projectIds: [Int]) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Id: $siteId
      project_Id_In: $projectIds
    ) {
      id
    }
  }
`;

export const GET_OWNER_OSP_ID = gql`
  query ownerSiteProject($ownerId: Int!) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Isnull: true
      project_Isnull: true
    ) {
      id
    }
  }
`;

export const GET_PARENT_CO = gql`
  query company($id: Int) {
    company(id: $id) {
      id
      parent {
        id
        parent {
          id
          parent {
            id
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_USER_CO = gql`
  query currentUser {
    currentUser {
      company {
        id
      }
    }
  }
`;

export const GET_SITE_OSP = gql`
  query getOSP($ownerId: Int, $siteId: Int) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Id: $siteId
      project_Isnull: true
    ) {
      id
    }
  }
`;
