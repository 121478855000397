import { gql } from "@apollo/client";

export const GET_INCIDENT_REPORTS = gql`
  query getIncidentReports {
    incidentReports(isActive: true) {
      id
      timeCreated
      date
      type
      isCompleted
      submitter {
        id
        username
        firstName
        lastName
      }
      incident {
        name
      }
      ha {
        name
        date
      }
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_INCIDENT_REPORT = gql`
  query getIncidentReport($id: Int) {
    incidentReport(id: $id) {
      id
      timeCreated
      date
      submitter {
        id
        username
        firstName
        lastName
      }
      incident {
        name
      }
      ha {
        name
        date
      }
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
      incidentReportArms(isActive: true) {
        arm {
          id
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_INVESTIGATION_FORM = gql`
  query getIncidentForm {
    incident(id: 1) {
      id
      name
      incidentTopics(isActive: true) {
        id
        name
        ordering
        incidentQuestions(isActive: true) {
          id
          name
          ordering
          type
          category
          incidentQuestionOptions(isActive: true) {
            ordering
            option {
              id
              name
              category
            }
          }
        }
      }
    }
  }
`;

export const GET_INVESTIGATION_RESPONSES = gql`
  query getIncidentReport($id: Int) {
    incidentReport(id: $id) {
      id
      date
      time
      weather
      timeCompleted
      isCompleted
      employeePhoneNumber
      employeeJobTitle
      employeeName
      employeeDob
      injuryDate
      injuryTime
      type
      submitter {
        username
        company {
          logoBase64
        }
      }
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
      ha {
        id
        name
      }
      incidentReportArms {
        id
        arm {
          id
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
      incidentReportCrewMembers {
        id
        crewMember {
          id
          phoneNumber
        }
      }
      incident {
        id
        name
        incidentTopics {
          id
          name
          ordering
          incidentQuestions {
            id
            name
            ordering
            type
            category
            incidentQuestionOptions(isActive: true) {
              option {
                id
                name
                category
              }
            }
          }
        }
      }
      incidentTopicResponses(isActive: true) {
        id
        note
        incidentTopic {
          id
          name
          ordering
        }
        files(isActive: true) {
          id
          file
        }
      }
      incidentQuestionResponses(isActive: true) {
        id
        textInput
        textBox
        multipleChoice
        checkbox
        boolean
        date
        time
        incidentQuestion {
          id
          name
          ordering
          type
          category
        }
        options(isActive: true) {
          id
          isActive
          option {
            id
            name
            category
            isActive
          }
        }
        files(isActive: true) {
          id
          file
        }
        dateRanges(isActive: true) {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

export const GET_HA_FOR_INCIDENT = gql`
  query recentHAs($after_date: Date, $first: Int) {
    hazardAnalyses(isActive: true, first: $first, fAfterDate: $after_date) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          id
          haId
          revisionNumber
          comment
          haCrewMembers {
            crewMember {
              id
              firstName
              lastName
              phoneNumber
              jobTitle {
                id
                name
              }
            }
          }
          haOwnerSiteProject {
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
              project {
                id
                name
              }
            }
          }
          submitter {
            id
            username
            company {
              name
            }
          }
          date
          time
          raScore
          hasInjury
          isCompleted
        }
      }
    }
  }
`;
