import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import styles from "./styles";

export default function ResponsesPage({
  showAll,
  topicOrdering,
  topicQuestions,
}) {
  const getTextColor = (response) => {
    if (response === 1) return "green";
    if (response === -1) return "red";
    return "black";
  };

  const getViolationText = (q) => {
    if (q.auditQuestionStandardNumber)
      return `${q.auditQuestionStandardNumber} - $${q.auditQuestionPenalty}`;
    return `$${q.auditQuestionPenalty}`;
  };

  return (
    <Page size="A4">
      <View style={{ border: "3px solid black", margin: "20px" }}>
        <View
          style={{
            borderTop: "3px solid #8297A0",
            borderBottom: "3px solid black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={styles.smallerTableSection}>
            <Text style={styles.text}>Audit Topic</Text>
          </View>
          <View style={styles.largerTableSection}>
            <Text style={styles.text}>Audit Findings</Text>
          </View>
          <View style={styles.largerTableSection}>
            <Text style={styles.text}>Comments</Text>
          </View>
          <View style={styles.tableSection}>
            <Text style={styles.text}>OSHA Estimated Penalties</Text>
          </View>
        </View>
        {topicOrdering.map((topic, index) => {
          const questions = showAll
            ? topicQuestions.filter((q) => q.auditTopicId === topic.id)
            : topicQuestions.filter(
                (q) =>
                  q.auditTopicId === topic.id &&
                  (q.response === -1 ||
                    q.comment !== "" ||
                    q.auditQuestionImages.length > 0)
              );

          if (questions.length > 0) {
            return (
              <View
                key={topic.id}
                style={{
                  borderBottom:
                    index !== topicOrdering.length - 1
                      ? "3px solid black"
                      : undefined,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={styles.smallerTableSection}>
                  <Text wrap={false} style={styles.text}>
                    {topic.name}
                  </Text>
                </View>
                <View>
                  {sortByKeys(questions, "auditQuestionOrder").map(
                    (q, index) => (
                      <View
                        wrap={false}
                        key={`${q.auditQuestion}-${q.auditTopicId}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderBottom:
                            index !==
                            topicQuestions.filter(
                              (q) => q.auditTopicId === topic.id
                            ).length -
                              1
                              ? "2px solid #8297A0"
                              : undefined,
                          flexWrap: "wrap",
                        }}
                      >
                        <View style={styles.largerTableSection}>
                          <Text
                            style={[
                              styles.text,
                              {
                                color: getTextColor(q.response),
                              },
                            ]}
                          >
                            {q.auditQuestion}
                          </Text>
                        </View>
                        <View style={styles.largerTableSection}>
                          <Text style={styles.text}>{q.comment}</Text>
                        </View>
                        <View style={styles.tableSection}>
                          <Text style={styles.text}>
                            {q.response === 1 &&
                              "No Violation Identified - See comments or photos for details"}
                            {q.response === -1 ? getViolationText(q) : null}
                          </Text>
                        </View>
                        <View style={{ width: "100%" }}>
                          {q.auditQuestionImages.map((p) => {
                            return (
                              <Image
                                key={p.id}
                                src={p.image}
                                style={{
                                  width: "25%",
                                  height: "auto",
                                  margin: "5px",
                                  border: "1px solid black",
                                }}
                              />
                            );
                          })}
                        </View>
                      </View>
                    )
                  )}
                </View>
              </View>
            );
          } else return null;
        })}
      </View>
    </Page>
  );
}

ResponsesPage.propTypes = {
  showAll: PropTypes.bool,
  topicOrdering: PropTypes.array,
  topicQuestions: PropTypes.array,
};
