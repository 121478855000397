import * as React from "react";

import TextField from "@mui/material/TextField";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function EmployeeJob({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (event) => {
    updateObsKeyFunction(
      "employeeJobTitle",
      event.target.value ? event.target.value : ""
    );
  };
  return (
    <TextField
      value={form.employeeJobTitle.name ?? form.employeeJobTitle}
      variant="standard"
      label="Job Title"
      onChange={handleChange}
      sx={{ width: "100%" }}
    />
  );
}
