import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import {
  useMutation,
  useQuery,
  useReactiveVar,
  useLazyQuery,
} from "@apollo/client";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { OBSERVATION_EMAIL } from "../../../graphql/mutations/observations";
import { GET_COMPANY_ARMS_NO_RBAC } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_SINGLE_OBSERVATION } from "../../../graphql/queries/observations";
import MobilePDFWarning from "../../CustomComponents/MobilePDFWarning";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import { PDFDocument } from "./PDF/PDFDocument";
import ViewImages from "./ViewImages";

export default function ViewSingleObservation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const { data, loading } = useQuery(GET_SINGLE_OBSERVATION, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [getCompanyMit, { data: arms, loading: loadingArms }] = useLazyQuery(
    GET_COMPANY_ARMS_NO_RBAC
  );

  const [sendEmailMutation] = useMutation(OBSERVATION_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  React.useEffect(() => {
    if (data?.observation?.submitter?.company) {
      const companyId = Number(data.observation.submitter.company.id);
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [data]);

  const navigateToEdit = () => {
    navigate(`/edit-obs/${id}`);
  };

  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      const img = [];
      data.observation.observationArms.forEach((arm) =>
        arm.observationArmMediaFiles.forEach((i) => img.push(i))
      );
      setImages(img);
    }
  }, [data]);

  const sendEmail = async () => {
    if (data && arms) {
      let blobPdf = await pdf(PDFDocument(data.observation, arms)).toBlob();
      let file = new File([blobPdf], "pdf_observation", {
        type: "application/pdf",
      });
      sendEmailMutation({
        variables: {
          id: Number(id),
          file,
        },
      });
    } else
      onErrorFunc(
        "Please try again momentarily after all Observation data has loaded."
      );
  };

  const isOwner =
    data?.observation.submitter.username === currentUser?.currentUser.username;

  return (
    <Layout>
      <Grid container justifyContent="space-between" alignContent="center">
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
        >
          VIEW OBSERVATION.
        </Typography>
        <Button
          onClick={() => navigate("/view-obs")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>

        {loading || loadingArms ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              {(isOwner || permissions.includes("EDIT_OTHER_OBSERVATION")) && (
                <Button
                  variant="contained"
                  color="yellow0"
                  onClick={navigateToEdit}
                >
                  EDIT
                </Button>
              )}
              <Button
                sx={{ mx: 2 }}
                variant="contained"
                color="secondary"
                onClick={sendEmail}
              >
                SEND TO EMAIL
              </Button>
              {data && arms && (
                <PDFDownloadLink
                  document={PDFDocument(data.observation, arms)}
                  fileName={`OBSERVATION_${data.observation.name}`.replace(
                    /[:<>*?".|\\/\s]/g,
                    ""
                  )}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      <p style={{ color: "white" }}>Loading PDF...</p>
                    ) : (
                      <div>
                        <Button variant="contained" color="primary">
                          DOWNLOAD PDF
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}

              <ViewImages images={images} />
            </Grid>
            <Grid item xs={12}>
              {data && arms && (
                <Grid>
                  {isTablet ? (
                    <MobilePDFWarning />
                  ) : (
                    <>
                      <BrowserView>
                        {data?.observation && (
                          <PDFViewer width="100%" height="800px">
                            {PDFDocument(data.observation, arms)}
                          </PDFViewer>
                        )}
                      </BrowserView>
                      <MobileView>
                        <MobilePDFWarning />
                      </MobileView>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
