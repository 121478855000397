import React from "react";

import { Document } from "@react-pdf/renderer";

import sortByKeys from "../../../../utils/sortByKeys";
import FirstPage from "./FirstPage";
import ResponsesPage from "./ResponsesPage";
import ReviewPage from "./ReviewPage";

export const PDFDocument = (auditSubmission, auditTopics, showAll) => {
  const topicOrdering = sortByKeys(auditTopics.audit.topics, "ordering");

  const topicQuestions = auditSubmission.responses.map((r) => {
    return {
      comment: r.comment,
      response: r.response,
      auditTopicId: r.auditTopicQuestion.auditTopic.id,
      auditTopicName: r.auditTopicQuestion.auditTopic.name,
      auditTopicQuestion: r.auditTopicQuestion.id,
      auditQuestion: r.auditTopicQuestion.name,
      auditQuestionOrder: r.auditTopicQuestion.ordering,
      auditQuestionPenalty: r.auditTopicQuestion.penalty,
      auditQuestionScore: r.auditTopicQuestion.score,
      auditQuestionStandardNumber: r.auditTopicQuestion.standardNumber,
      auditQuestionImages: r.images,
    };
  });

  return (
    <Document pageMode="fullScreen" title="Audit Report" author="NIXN">
      <FirstPage
        auditSubmission={auditSubmission}
        topicOrdering={topicOrdering}
        topicQuestions={topicQuestions}
      />
      <ResponsesPage
        showAll={showAll}
        topicOrdering={topicOrdering}
        topicQuestions={topicQuestions}
      />
      <ReviewPage auditSubmission={auditSubmission} />
    </Document>
  );
};
