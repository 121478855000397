import React from "react";

import { Document } from "@react-pdf/renderer";

import GeneralInformation from "./GeneralInformation";
import Topics from "./Topics";

const PDFDocument = (i) => {
  return (
    <Document
      pageMode="fullScreen"
      title="Incident Investigation"
      author="NIXN"
    >
      <GeneralInformation i={i} />
      <Topics i={i} />
    </Document>
  );
};

export default PDFDocument;
