import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_OWNERS } from "../../../../graphql/queries/admin/planning-site";
import sortByKeys from "../../../../utils/sortByKeys";

export default function Owner({ owner, setOwner, setSite }) {
  // Queries
  const { data, loading } = useQuery(GET_OWNERS);

  const handleChange = (event, newValue) => {
    setOwner(newValue || []);
    setSite([]);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      id="select-owner"
      options={data ? sortByKeys(data.ownerSiteProjects, "owner.name") : []}
      value={owner}
      multiple
      getOptionLabel={(option) => option.owner.name}
      onChange={handleChange}
      loading={loading}
      isOptionEqualToValue={(option, value) =>
        option.owner.id === value.owner.id
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={`${option.id}`}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.owner.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Owner(s):"
          value={params}
          id={params.id}
        />
      )}
    />
  );
}
