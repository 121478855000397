import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { INCIDENT_EMAIL } from "../../../graphql/mutations/investigation";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_INVESTIGATION_RESPONSES } from "../../../graphql/queries/incidentInvestigations";
import MobilePDFWarning from "../../CustomComponents/MobilePDFWarning";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CloseIncident from "./CloseIncident";
import PDFDocument from "./PDF/PDFDocument";
import ViewFiles from "./ViewFiles";

export default function ViewSingleInvestigation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const { data, loading } = useQuery(GET_INVESTIGATION_RESPONSES, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  const [emailMutation] = useMutation(INCIDENT_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const navigateToEdit = () => {
    navigate(`/edit-incident/${id}`);
  };

  const sendEmail = async () => {
    let blobPdf = await pdf(PDFDocument(data.incidentReport)).toBlob();
    let file = new File([blobPdf], "pdf_incident_report", {
      type: "application/pdf",
    });
    const report = data.incidentReport;
    emailMutation({
      variables: { id: Number(report.id), file },
    });
  };

  const isOwner =
    data?.incidentReport.submitter.username ===
    currentUser?.currentUser.username;

  const closed = data?.incidentReport.isCompleted;

  const d = new Date(data?.incidentReport.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return (
    <Layout>
      <Grid container justifyContent="space-between" alignContent="center">
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
        >
          VIEW INCIDENT INVESTIGATION.
        </Typography>
        <Button
          onClick={() => navigate("/view-incident")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>

        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              {!closed &&
                (isOwner ||
                  permissions.includes("EDIT_OTHER_INCIDENT_REPORT")) && (
                  <Button
                    variant="contained"
                    color="yellow0"
                    onClick={navigateToEdit}
                  >
                    EDIT
                  </Button>
                )}
              {!closed && permissions.includes("CLOSE_INCIDENT_REPORT") && (
                <CloseIncident />
              )}

              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="secondary"
                onClick={sendEmail}
              >
                SEND TO EMAIL
              </Button>
              {data && (
                <>
                  <PDFDownloadLink
                    document={PDFDocument(data.incidentReport)}
                    fileName={`INCIDENT_REPORT_${moment(convertedDate).format(
                      "MMDDYYYY"
                    )}`.replace(/[:<>*?".|\\/\s]/g, "")}
                    style={{ textDecoration: "none" }}
                  >
                    {({ loading }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mx: 2 }}
                          >
                            DOWNLOAD PDF
                          </Button>
                        </div>
                      )
                    }
                  </PDFDownloadLink>

                  <ViewFiles
                    files={data.incidentReport.incidentTopicResponses}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {data && (
                <Grid>
                  {isTablet ? (
                    <MobilePDFWarning />
                  ) : (
                    <>
                      <BrowserView>
                        {data?.incidentReport && (
                          <PDFViewer width="100%" height="800px">
                            {PDFDocument(data.incidentReport)}
                          </PDFViewer>
                        )}
                      </BrowserView>
                      <MobileView>
                        <MobilePDFWarning />
                      </MobileView>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
