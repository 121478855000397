import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import logo from "../../../../images/nixn-logo-white.png";
import { getAuditScores } from "../../../../utils/raScore";
import styles from "./styles";

export default function FirstPage({
  auditSubmission,
  topicOrdering,
  topicQuestions,
}) {
  const tableTextStyle = {
    fontSize: "8px",
  };

  const tableCellStyle = {
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
    padding: "3px 5px",
  };

  const positiveSections = [];
  const negativeSections = [];
  topicOrdering.forEach((topic) => {
    const allQuestions = topicQuestions.filter(
      (q) => Number(q.auditTopicId) === Number(topic.id)
    );
    const positiveQuestions = topicQuestions.filter(
      (q) => Number(q.auditTopicId) === Number(topic.id) && q.response === 1
    );
    const negativeQuestions = topicQuestions.filter(
      (q) => Number(q.auditTopicId) === Number(topic.id) && q.response === -1
    );

    const { raScore, penalty } = getAuditScores([topic], allQuestions);

    if (positiveQuestions.length > 0) {
      positiveSections.push({
        name: topic.name,
        number: topic.ordering + 1,
        score: raScore,
        positiveResponses: positiveQuestions.length,
      });
    }

    if (negativeQuestions.length > 0) {
      negativeSections.push({
        name: topic.name,
        number: topic.ordering + 1,
        score: raScore,
        negativeResponses: negativeQuestions.length,
        penalty,
      });
    }
  });

  return (
    <Page size="A4">
      <View style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "280px",
              textAlign: "center",
            }}
          >
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              {auditSubmission.audit.name.toUpperCase()}
            </Text>
          </View>
          <View
            style={{ width: "25%", borderBottom: "3px solid #FCA800" }}
          ></View>
          {auditSubmission.submitter?.company?.logoBase64 !== "" ? (
            <Image
              src={`data:image;base64,${auditSubmission.submitter.company.logoBase64}`}
              style={{ width: "100px" }}
            />
          ) : (
            <Image src={logo} style={{ width: "100px" }} />
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <View
            style={{
              border: "3px solid #8297A0",
              borderRadius: 5,
              width: "150px",
              textAlign: "center",
            }}
          >
            <Text style={styles.text}>LOCATION:</Text>
            <Text style={styles.text}>
              {auditSubmission.ownerSiteProject.site.name}
            </Text>
            <Text style={[styles.text, { paddingTop: 0 }]}>
              {`[${auditSubmission.ownerSiteProject.owner.name}]`}
            </Text>
          </View>
          <View
            style={{
              border: "3px solid #8297A0",
              borderRadius: 5,
              width: "150px",
              textAlign: "center",
            }}
          >
            <Text style={styles.text}>RISK EXPOSURE SCORE:</Text>
            <Text style={styles.text}>{auditSubmission.raScore}%</Text>
          </View>
          <View
            style={{
              border: "3px solid #8297A0",
              borderRadius: 5,
              width: "150px",
              textAlign: "center",
            }}
          >
            <Text style={styles.text}>POTENTIAL CITATIONS:</Text>
            <Text style={styles.text}>
              ${parseFloat(auditSubmission.penalty.toFixed(2)).toLocaleString()}
            </Text>
          </View>
        </View>

        {/* POSITIVE AUDIT */}
        <View
          style={{
            border: "1px solid black",
            width: "100%",
            marginTop: "20px",
            backgroundColor: "#8297a0",
            textAlign: "center",
            padding: "3px 5px",
          }}
        >
          <Text style={{ color: "green", fontSize: "10px" }}>
            Positive Audit Findings
          </Text>
        </View>
        <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <View style={{ width: "50%", ...tableCellStyle }}>
            <Text style={tableTextStyle}>Audit Item #</Text>
          </View>
          <View style={{ width: "25%", ...tableCellStyle }}>
            <Text style={tableTextStyle}>Risk Exposure Score</Text>
          </View>
          <View
            style={{
              width: "25%",
              ...tableCellStyle,
              borderRight: "1px solid black",
            }}
          >
            <Text style={tableTextStyle}>Positive Responses</Text>
          </View>
        </View>
        {positiveSections.map((section) => (
          <View
            key={section.number}
            style={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <View style={{ width: "50%", ...tableCellStyle }}>
              <Text style={tableTextStyle}>
                {section.number} {section.name}
              </Text>
            </View>
            <View style={{ width: "25%", ...tableCellStyle }}>
              <Text style={tableTextStyle}>
                {section.score.toFixed(2) * 100}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                ...tableCellStyle,
                borderRight: "1px solid black",
              }}
            >
              <Text style={tableTextStyle}>{section.positiveResponses}</Text>
            </View>
          </View>
        ))}

        {/* NEGATIVE AUDIT */}
        <View
          style={{
            border: "1px solid black",
            width: "100%",
            marginTop: "20px",
            backgroundColor: "#8297a0",
            textAlign: "center",
            padding: "3px 5px",
          }}
        >
          <Text style={{ color: "red", fontSize: "10px" }}>
            Audit Findings Which Could Result in OSHA Citation
          </Text>
        </View>
        <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <View style={{ width: "50%", ...tableCellStyle }}>
            <Text style={tableTextStyle}>Audit Item #</Text>
          </View>
          <View style={{ width: "25%", ...tableCellStyle }}>
            <Text style={tableTextStyle}>Risk Exposure Score</Text>
          </View>
          <View
            style={{
              width: "25%",
              ...tableCellStyle,
              borderRight: "1px solid black",
            }}
          >
            <Text style={tableTextStyle}>OSHA Estimated Score (Penalty)</Text>
          </View>
        </View>
        {negativeSections.map((section) => (
          <View
            key={section.number}
            style={{ width: "100%", display: "flex", flexDirection: "row" }}
          >
            <View style={{ width: "50%", ...tableCellStyle }}>
              <Text style={tableTextStyle}>
                {section.number} {section.name}
              </Text>
            </View>
            <View style={{ width: "25%", ...tableCellStyle }}>
              <Text style={tableTextStyle}>
                {section.score.toFixed(2) * 100}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                ...tableCellStyle,
                borderRight: "1px solid black",
              }}
            >
              <Text style={tableTextStyle}>
                {section.negativeResponses} (${section.penalty})
              </Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  );
}

FirstPage.propTypes = {
  auditSubmission: PropTypes.object,
  topicOrdering: PropTypes.array,
  topicQuestions: PropTypes.array,
};
