import React from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { EDIT_HA } from "../../../graphql/mutations/ha";
import { VIEW_HA } from "../../../graphql/queries/ha";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";

export default function CloseHA({ ha }) {
  const { id } = useParams();
  const [editHAMutation] = useMutation(EDIT_HA, {
    onCompleted() {
      onCompletedFunc("Ha will be closed");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: VIEW_HA, variables: { id: Number(id) } }],
  });

  const [openClose, setOpenClose] = React.useState(false);

  const [hasInjury, setHasInjury] = React.useState(false);
  const [injuryText, setInjuryText] = React.useState("");

  React.useEffect(() => {
    setHasInjury(ha.hasInjury);
    setInjuryText(ha.injuryText);
  }, []);

  const handleChangeHasInjury = (event) => {
    setHasInjury(event.target.checked);
  };

  const onChangeInjuryText = (event) => {
    setInjuryText(event.target.value);
  };

  const handleConfirmCloseHA = () => {
    setOpenClose(false);
    editHAMutation({
      variables: {
        id: Number(ha.haId),
        input: {
          isCompleted: true,
          timeCompleted: new Date(),
          name: ha.name,
          comment: ha.comment,
          hasInjury: hasInjury,
          injuryText: injuryText,
          date: ha.date,
          ownerSiteProjects: ha.haOwnerSiteProjects.map((osp) => ({
            id: Number(osp.ownerSiteProject.id),
          })),
          arms: ha.haArms.map((arm) => {
            let copy = { id: Number(arm.arm.id) };
            if (arm.actionOrdering) {
              copy = { ...copy, actionOrdering: arm.actionOrdering };
            }
            return copy;
          }),
          templates: ha.haTemplate.map((t) => ({
            id: Number(t.template.id),
          })),
          crewMembers: ha.haCrewMembers.map((crew) => {
            let copy = { id: Number(crew.crewMember.id) };
            return copy;
          }),
        },
      },
    });
  };

  return (
    <>
      <Button
        style={{
          width: "fit-content",
          backgroundColor: "red",
          color: "white",
          margin: "0px 20px",
        }}
        onClick={() => setOpenClose(true)}
        variant="contained"
      >
        CLOSE
      </Button>

      <CustomDialog open={openClose} onClose={() => setOpenClose(false)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                Were there any incidents associated with this HA?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ color: "white" }}
                control={
                  <CustomSwitch
                    checked={hasInjury}
                    onChange={handleChangeHasInjury}
                    light
                  />
                }
                label={hasInjury ? "YES" : "NO"}
              />
            </Grid>

            <Grid item key={"incident"} xs={12} style={{ marginTop: "10px" }}>
              <CustomTextField
                value={injuryText}
                variant="standard"
                label={"Please describe the incident:"}
                multiline={true}
                onChange={onChangeInjuryText}
                style={
                  hasInjury
                    ? {
                        width: "100%",
                      }
                    : {
                        display: "none",
                        width: "100%",
                      }
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              width: "fit-content",
              backgroundColor: "red",
              color: "white",
            }}
            onClick={handleConfirmCloseHA}
          >
            CLOSE HA
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenClose(false)}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

CloseHA.propTypes = {
  ha: PropTypes.object,
};
