import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";
import sortByKeys from "../../../../utils/sortByKeys";

export default function Projects({ type, data }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Local Variables
  const [projects, setProjects] = React.useState([]);

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (event, newValue) => {
    updateObsKeyFunction("project", newValue);
  };

  React.useEffect(() => {
    if (form.ha && form.ha.haOwnerSiteProjects) {
      setProjects(
        form.ha.haOwnerSiteProjects.map((osp) => osp.ownerSiteProject.project)
      );
    } else {
      if (data) {
        setProjects(data.ownerSiteProjects.map((osp) => osp.project));
      }
    }
  }, [data, form.ha]);

  React.useEffect(() => {}, [form.project]);

  return (
    <Autocomplete
      id="obs-select-projects"
      options={sortByKeys(projects, "name")}
      value={form.project || { name: "" }}
      getOptionLabel={(option) => {
        return option.name;
      }}
      onChange={handleChange}
      disabled={!form.site}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          value={params}
          variant="standard"
          label="Project:"
        />
      )}
    />
  );
}
