import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import {
  Typography,
  Grid,
  Button,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { authMutations } from "../../../graphql/mutations";
import { HA_EMAIL } from "../../../graphql/mutations/ha";
import { GET_COMPANY_ARMS_NO_RBAC } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { VIEW_HA } from "../../../graphql/queries/ha";
import MobilePDFWarning from "../../CustomComponents/MobilePDFWarning";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CloseHA from "./CloseHA";
import { PDFDocument } from "./PDF/PDFDocument";

export default function ViewHA() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);
  const { updateLoading } = authMutations;

  // QUERIES
  const { data, loading: loadingHA } = useQuery(VIEW_HA, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [getCompanyMit, { data: arms, loading: loadingMit }] = useLazyQuery(
    GET_COMPANY_ARMS_NO_RBAC,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  // MUTATIONS
  const [emailHAMutation] = useMutation(HA_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [scores, setScores] = React.useState(true);

  React.useEffect(() => {
    if (data?.hazardAnalysis?.submitter?.company) {
      const companyId = Number(data.hazardAnalysis.submitter.company.id);
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [data]);

  React.useEffect(() => {
    updateLoading(loadingHA || loadingMit);
  }, [loadingHA, loadingMit]);

  const isOwner =
    data?.hazardAnalysis.submitter.username ===
    currentUser?.currentUser.username;

  const sendEmail = async () => {
    let blobPdf = await pdf(
      PDFDocument(data.hazardAnalysis, arms, scores)
    ).toBlob();
    let file = new File([blobPdf], "file_name", { type: "application/pdf" });
    emailHAMutation({
      variables: {
        id: Number(data.hazardAnalysis.haId),
        file,
      },
    });
  };

  const getPDFButton = () => {
    const d = new Date(data.hazardAnalysis.date);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    return (
      <PDFDownloadLink
        document={PDFDocument(data.hazardAnalysis, arms, scores)}
        fileName={`HA_${data.hazardAnalysis.name}_${moment(
          convertedDate
        ).format("MMDDYYYY")}`.replace(/[:<>*?".|\\/\s]/g, "")}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) =>
          loading ? (
            <CircularProgress />
          ) : (
            <div>
              <Button variant="contained" color="primary" sx={{ ml: 1 }}>
                DOWNLOAD PDF
              </Button>
            </div>
          )
        }
      </PDFDownloadLink>
    );
  };

  return (
    <Layout>
      <Grid container justifyContent="space-between">
        <Typography sx={pageTitleStyles}>VIEW HAZARD ANALYSIS.</Typography>
        <Button onClick={() => navigate("/view-ha")} style={{ color: "white" }}>
          GO BACK
        </Button>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: "20px" }}
        >
          {(isOwner || permissions.includes("EDIT_OTHER_HA")) && (
            <>
              {!data?.hazardAnalysis.isCompleted && (
                <Button
                  onClick={() =>
                    navigate(`/edit-ha/${data?.hazardAnalysis.haId}`)
                  }
                  variant="contained"
                  color="yellow0"
                >
                  EDIT
                </Button>
              )}
              {data?.hazardAnalysis && !data?.hazardAnalysis?.isCompleted && (
                <CloseHA ha={data.hazardAnalysis} />
              )}
              {data?.hazardAnalysis?.isCompleted &&
                data?.hazardAnalysis?.timeCompleted && (
                  <div style={{ marginRight: "20px" }}>
                    <Typography style={{ color: "white" }}>
                      COMPLETED AT:{" "}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {moment(
                        new Date(data.hazardAnalysis.timeCompleted)
                      ).format("MM/DD/YYYY hh:mm A")}
                    </Typography>
                  </div>
                )}
            </>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={sendEmail}
            disabled={!data?.hazardAnalysis && !arms}
          >
            SEND TO EMAIL
          </Button>

          <Grid
            sx={{
              ml: 4,
            }}
          >
            <Typography style={{ color: "white" }}>Include Scores?</Typography>
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={scores}
                  onChange={(event) => setScores(event.target.checked)}
                  light
                />
              }
              label={scores ? "YES" : "NO"}
            />
          </Grid>
          {data?.hazardAnalysis && arms && getPDFButton()}
        </Grid>
        <Grid item xs={12}>
          {data?.hazardAnalysis && arms && (
            <Grid>
              {isTablet ? (
                <MobilePDFWarning />
              ) : (
                <>
                  <BrowserView>
                    <PDFViewer width="100%" height="800px">
                      {PDFDocument(data.hazardAnalysis, arms, scores)}
                    </PDFViewer>
                  </BrowserView>
                  <MobileView>
                    <MobilePDFWarning />
                  </MobileView>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
