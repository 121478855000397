import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { viewIncidentFiltersVar } from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function TableFilterDialog({
  open,
  setOpen,
  clearFilters,
  usernames,
  sites,
}) {
  const { updateKeyViewIncidentFilters } = investigationMutations;
  const filters = useReactiveVar(viewIncidentFiltersVar);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-site"
              options={sites.toSorted((a, b) =>
                a.site.name > b.site.name ? 1 : -1
              )}
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site || { owner: { name: "" } }}
              onChange={(event, newValue) =>
                updateKeyViewIncidentFilters(
                  "site",
                  newValue || { owner: { name: "" } }
                )
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-usernames"
              options={usernames.toSorted((a, b) =>
                a.username > b.username ? 1 : -1
              )}
              getOptionLabel={(option) => {
                return option.username;
              }}
              value={filters.username || { username: "" }}
              onChange={(event, newValue) =>
                updateKeyViewIncidentFilters(
                  "username",
                  newValue || { username: "" }
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Submitter"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-incident-type"
              options={["Injury", "Property Damage", "Near Miss"]}
              value={filters.type || ""}
              onChange={(event, newValue) =>
                updateKeyViewIncidentFilters("type", newValue || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Incident Type"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "5px" }} container>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.afterDate || null}
              onChange={(date) =>
                updateKeyViewIncidentFilters("afterDate", date)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "47%", marginRight: "3%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.beforeDate || null}
              onChange={(date) =>
                updateKeyViewIncidentFilters("beforeDate", date)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "50%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  clearFilters: PropTypes.func,
  usernames: PropTypes.array,
  sites: PropTypes.array,
};
