import React from "react";

import {
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../graphql/localVariables/user";
import { CREATE_NEW_USER } from "../../../graphql/mutations/userSettings";
import { GET_COMPANY_TREE } from "../../../graphql/queries";
import sortByKeys from "../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import PasswordField from "../../CustomComponents/PasswordField";

export const checkAccountInfo = (
  username,
  password,
  passwordConfirm,
  email,
  company,
  onErrorFunc
) => {
  if (username === "") {
    onErrorFunc("Please enter a username.");
    return false;
  } else if (!/^[A-Za-z0-9@.+-_]*$/.test(username)) {
    onErrorFunc("Please use only valid characters in the username.");
    return false;
  } else if (username.length > 150) {
    onErrorFunc("Username is too long.");
    return false;
  } else if (email === "") {
    onErrorFunc("Please enter an email.");
    return false;
  } else if (
    !email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    onErrorFunc("Please enter a valid email.");
    return false;
  } else if (company.name === "") {
    onErrorFunc("Please enter a company.");
    return false;
  } else if (password === "") {
    onErrorFunc("Please enter a password.");
  } else if (password.length < 8) {
    onErrorFunc("Password is not long enough.");
    return false;
  } else if (!/[a-z]/i.test(password)) {
    onErrorFunc("Password must not only include numbers.");
    return false;
  } else if (password === "password" || password === username) {
    onErrorFunc("Password must not be a common password.");
    return false;
  } else if (passwordConfirm === "") {
    onErrorFunc("Please confirm the new password.");
    return false;
  } else if (password !== passwordConfirm) {
    onErrorFunc("Make sure the password confirmation match.");
    return false;
  } else return true;
};

export default function CreateNewUser() {
  const online = useReactiveVar(onlineVar);
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState({ name: "" });
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const { data } = useQuery(GET_COMPANY_TREE, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [createNewUser] = useMutation(CREATE_NEW_USER, {
    onCompleted() {
      setUsername("");
      setEmail("");
      setCompany({ name: "" });
      setPassword("");
      setPasswordConfirm("");
      onCompletedFunc("User was created");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const createUser = () => {
    if (
      checkAccountInfo(
        username,
        password,
        passwordConfirm,
        email,
        company,
        onErrorFunc
      )
    ) {
      createNewUser({
        variables: {
          username: username.toLowerCase(),
          email,
          password,
          companyId: Number(company.id),
        },
      });
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        variant="standard"
        label="Username:"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <Typography>
        Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
      </Typography>
      <TextField
        variant="standard"
        label="Email Address:"
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <Autocomplete
        disablePortal
        id="create-user-company"
        options={data ? sortByKeys(data.companies, "name") : []}
        value={company}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => setCompany(newValue)}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Company:" />
        )}
      />
      <PasswordField
        label="Password:"
        password={password}
        setPassword={setPassword}
      />
      <Typography>Password must contain at least 8 characters.</Typography>
      <Typography>Password can&apos;t be a commonly used password.</Typography>
      <Typography>Password can&apos;t be entirely numeric.</Typography>
      <PasswordField
        label="Password Confirmation:"
        password={passwordConfirm}
        setPassword={setPasswordConfirm}
      />
      <Typography>
        Enter the same password as before, for verification.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: 1 }}
        onClick={createUser}
      >
        CREATE
      </Button>
    </FormControl>
  );
}
