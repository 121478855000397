import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import logo from "../../../../images/nixn-logo-white.png";
import { styles } from "./styles";

export default function FirstPage({ observation }) {
  const unplanned = observation.automaticCheck || observation.manualCheck;

  const outcomeColor = (() => {
    if (observation.observationOutcomes.length > 0) {
      const outcome = observation.observationOutcomes[0].outcome.name;
      return outcome === "Safe" ? "green" : "red";
    } else {
      return "white";
    }
  })();

  const outcomeText = (() => {
    if (observation.observationOutcomes.length > 0) {
      const outcomes = observation.observationOutcomes.map(
        (obsOut) => obsOut.outcome.name
      );
      return outcomes.join(", ");
    } else {
      return "";
    }
  })();

  const getRiskColor = (risk) => {
    if (risk >= 90) {
      return "red";
    } else if (risk >= 70) {
      return "orange";
    } else if (risk >= 50) {
      return "gold";
    } else return "green";
  };

  const supervisors = (() => {
    const activeSupervisors = observation.submitter?.supervisedBy?.filter(
      (s) => s.isActive
    );

    if (activeSupervisors.length > 0) {
      return activeSupervisors.map((s) => s.supervisor.username).join(", ");
    }
    return "";
  })();

  return (
    <Page size="A4">
      <View style={styles.page}>
        <View
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text style={{ fontFamily: "Helvetica-Bold", fontSize: "14px" }}>
              OBSERVATION
            </Text>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
          </View>
          <View
            style={{ width: "57%", borderBottom: "3px solid #FCA800" }}
          ></View>
          {observation.submitter?.company?.logoBase64 !== "" ? (
            <Image
              src={`data:image;base64,${observation.submitter.company.logoBase64}`}
              style={{ width: "100px" }}
            />
          ) : (
            <Image src={logo} style={{ width: "100px" }} />
          )}
        </View>
        <Text style={{ margin: "10px 0px" }}>
          {observation.name} -{" "}
          <Text
            style={{
              color: outcomeColor,
              paddingBottom: "20px",
            }}
          >
            {outcomeText}
          </Text>
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Owner</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {observation.ownerSiteProject.owner?.name}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Site</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {observation.ownerSiteProject.site?.name}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Project</Text>
            {observation.ownerSiteProject.project && (
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                {observation.ownerSiteProject.project?.name}
              </Text>
            )}
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Observed</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {observation.contractor ? observation.contractor.name : ""}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Submitted By</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {observation.submitter.username}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Supervisor</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {supervisors}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Outcome</Text>
            <Text
              style={{
                fontSize: "14px",
                color: outcomeColor,
                padding: "10px 10px 10px 0px",
              }}
            >
              {outcomeText}
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text style={styles.header}>Planned Status</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {/* check === false -> Planned work */}
              {unplanned ? "Unplanned" : "Planned"}
            </Text>
          </View>
          {observation.dollarAmount ? (
            <View style={styles.armSectionHeader}>
              <Text style={styles.header}>Dollar Amount</Text>
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                ${observation.dollarAmount}
              </Text>
            </View>
          ) : null}
          <View
            style={{
              marginTop: "40px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "33.3%",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                Event had Risk Assessment Score:
              </Text>
              <Text style={{ color: getRiskColor(observation.raScore) }}>
                {observation.raScore.toFixed(2)}
              </Text>
            </View>
            <View
              style={{
                width: "33.3%",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                Event had Risk Assessment Score (Pre-Mitigation):
              </Text>
              <Text style={{ color: getRiskColor(observation.raScoreMax) }}>
                {observation.raScoreMax.toFixed(2)}
              </Text>
            </View>
            <View
              style={{
                width: "33.3%",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                Event had Risk Assessment Score (Post-Corrections):
              </Text>
              <Text
                style={{
                  color: getRiskColor(observation.raScoreCorr),
                }}
              >
                {observation.raScoreCorr.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}

FirstPage.propTypes = {
  observation: PropTypes.object,
};
