import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, TextField, Button, Typography, Box } from "@mui/material";

import { useApolloClient } from "@apollo/client";
import axios from "axios";

import { apiUrl } from "../../config";
import { authMutations } from "../../graphql/mutations";
import logo from "../../images/nixn-logo-square.png";
import {
  loginTitleStyles,
  phonePageTitleStyles,
} from "../CustomStyles/pageTitle";
import handleLogout from "../SideNav/handleLogout";
import useStyles from "./styles";

const Form = ({ mobile }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const { updateAuth, updateSnackbar, updateCache } = authMutations;
  const passwordRef = React.useRef();

  const login = () => {
    axios
      .post(`${apiUrl}auth/jwt/login/`, {
        username: username.toLowerCase(),
        password,
      })
      .then(() => {
        updateCache(true); // true means need to cache data for new login
        setUsername("");
        setPassword("");
        updateAuth(true);
        axios.get(`${apiUrl}main/logged-in/`);
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: "Username or password is incorrect.",
          severity: "error",
        });
        updateAuth(false);
      });
  };

  const sendLogin = (event) => {
    event.preventDefault();
    if (username === "") {
      alert("Username cannot be blank.");
      return;
    }
    if (password === "") {
      alert("Password cannot be blank.");
      return;
    }
    login();
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      style={{ height: "100%" }}
    >
      <Grid item style={{ width: "100%" }}>
        <Typography
          style={
            mobile
              ? { ...phonePageTitleStyles, color: "black" }
              : { ...loginTitleStyles }
          }
        >
          POWERED BY MAC
        </Typography>
        <Typography
          style={
            mobile
              ? { ...phonePageTitleStyles, color: "black" }
              : { ...loginTitleStyles }
          }
        >
          INTELLIGENCE.
        </Typography>
        <Typography style={{ marginBottom: "20px" }}>
          Welcome, please login to your account.
        </Typography>
        <TextField
          data-testid="username-field"
          id={mobile ? "mobile-username" : "username"}
          onChange={(event) => setUsername(event.target.value)}
          value={username}
          variant="standard"
          label="Username"
          placeholder="Enter username"
          fullWidth
          required
          style={{ marginBottom: "20px" }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Return") {
              passwordRef.current.focus();
            }
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
        <TextField
          data-testid="password-field"
          id={mobile ? "mobile=passowrd" : "password"}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          variant="standard"
          label="Password"
          placeholder="Enter password"
          fullWidth
          required
          type="password"
          inputRef={passwordRef}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Return") {
              sendLogin(e);
            }
          }}
        />
        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
          <Button
            id="login-button"
            onClick={sendLogin}
            type="submit"
            color="secondary"
            variant="contained"
            style={{ width: "fit-content" }}
          >
            LOGIN
          </Button>
          <Button
            id="forgot-password-button"
            type="submit"
            color="secondary"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot password?
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function Login() {
  const classes = useStyles();
  const client = useApolloClient();

  React.useEffect(() => {
    // Make sure properly logged out if redirected
    handleLogout(client);
  }, []);

  const mobileLogin = (
    <div className={classes.loginBox}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid
          item
          style={{ backgroundColor: "white", padding: 20, width: "75%" }}
        >
          <Form mobile />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Box sx={{ display: { xs: "block", md: "none" } }}>{mobileLogin}</Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          style={{ backgroundColor: "#151711", height: 60, width: "100%" }}
          justifyContent="center"
        >
          <img src={logo} alt="NIXN logo" style={{ height: "100%" }} />
        </Grid>
        <div className={classes.loginBox}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{ height: "100%" }}
          >
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={5}
              style={{ height: "90%", backgroundColor: "white", padding: 20 }}
            >
              <Form />
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
}
