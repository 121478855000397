import React from "react";

import { Grid, TextField, Autocomplete } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { editHAVar, createHAVar } from "../../../graphql/localVariables/ha";
import { haMutations } from "../../../graphql/mutations";
import sortByKeys from "../../../utils/sortByKeys";
import { useStyles } from "./styles";

export default function AddActionInputView({ type, actions: data, loading }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const classes = useStyles()();
  const [unselectedActions, setUnselectedActions] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      if (updateHA.actions && updateHA.actions.length != 0) {
        setUnselectedActions(
          data.companyArms
            .map((carm) => carm.arm)
            .filter(
              (arm) =>
                updateHA.actions.findIndex(
                  (a) => Number(a.a?.id) === Number(arm.a?.id)
                ) < 0
            )
        );
      } else {
        setUnselectedActions(data.companyArms.map((carm) => carm.arm));
      }
    }
  }, [data, updateHA.actions]);

  const addAction = (value) => {
    if (value) {
      const prev = updateHA.actions ? updateHA.actions : [];
      const lastActionOrder = sortByKeys(updateHA.actions, "actionOrdering");
      if (lastActionOrder.length > 0) {
        const newVal = { ...value };
        newVal.actionOrdering =
          lastActionOrder[updateHA.actions.length - 1].actionOrdering + 1;
        updateHAFunction("actions", [...prev, newVal]);
      } else {
        const newVal = { ...value };
        newVal.actionOrdering = 0;
        updateHAFunction("actions", [...prev, newVal]);
      }
    }
  };

  React.useEffect(() => {}, [unselectedActions]);

  return (
    <Grid
      item
      key={"actions"}
      xs={12}
      sm={6}
      lg={3}
      className={classes.borderedBox}
    >
      <Autocomplete
        disablePortal
        id="ha-add-actions"
        value={{ a: { name: "" } }}
        options={
          unselectedActions ? sortByKeys(unselectedActions, "a.name") : []
        }
        onChange={(event, value) => addAction(value)}
        getOptionLabel={(option) => option.a.name}
        isOptionEqualToValue={() => true}
        loading={loading}
        renderOption={(props, option) => (
          <li {...props} key={option.a.id}>
            {option.a.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={"Add Actions:"} />
        )}
      />
    </Grid>
  );
}
