import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "20px",
  },
  formSection: { width: "147.3px", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "196.4px", border: "1px solid black" },
  column: { display: "flex", flexDirection: "column" },
  row: { display: "flex", flexDirection: "row" },
  text: { padding: "10px", fontSize: "10px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
  sectionLabel: { paddingBottom: "10px", fontSize: "10px" },
  tableSection: { width: "120px" },
  smallerTableSection: { width: "90px" },
  largerTableSection: { width: "165px" },
});

export default styles;
