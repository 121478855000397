import React from "react";

import { Grid, Typography } from "@mui/material";

import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";

export default function ViewAudits() {
  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography sx={pageTitleStyles}>VIEW AUDITS.</Typography>
        <Table />
      </Grid>
    </Layout>
  );
}
