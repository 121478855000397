import React from "react";

import {
  Badge,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import PropTypes from "prop-types";

import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import PreviewImages from "./PreviewImages";

export default function ViewImages({ images }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => [setOpen(false)];

  const onCancel = () => {
    handleClose();
  };

  return (
    <>
      <Badge color="secondary" badgeContent={images.length} showZero>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          VIEW IMAGES
        </Button>
      </Badge>

      <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle
          style={{
            ...pageTitleStyles,
          }}
        >
          VIEW AUDIT IMAGES.
        </DialogTitle>
        <DialogContent>
          <PreviewImages fileObjects={images} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancel}>
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

ViewImages.propTypes = {
  images: PropTypes.array.isRequired,
};
