import React from "react";

import { Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import { ObsImage } from "./ARMSection";

export default function ImagesSection({ observation }) {
  if (observation.observationMediaFiles.length > 0)
    return (
      <Page>
        <View
          style={{
            padding: "10px",
            borderTop: "3px solid grey",
            margin: "20px auto",
            width: "95%",
          }}
        >
          <Text style={{ marginBottom: "20px" }}>INCLUDED IMAGES</Text>

          {observation.observationMediaFiles.map((oFile) => {
            const isImage = (file) => {
              return ["png", "jpg", "jpeg"].some((v) => file.includes(v));
            };

            if (isImage(oFile.mediaFile.file)) {
              return <ObsImage img={oFile} key={oFile.id} />;
            }
          })}
        </View>
      </Page>
    );

  return null;
}

ImagesSection.propTypes = {
  observation: PropTypes.object,
};
