import React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Grid, Checkbox, Autocomplete, TextField } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import sortByKeys from "../../../../utils/sortByKeys";

export default function CheckboxQuestion({ question, type, updateState }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;
  const [foundRes, setFoundRes] = React.useState({
    incidentQuestion: { id: question.id },
    checkbox: "",
    options: [],
  });

  const handleChange = (value) => {
    // update State
    updateState({
      incidentQuestion: { id: question.id },
      checkbox: value.map((v) => v.option.name).join(),
      options: value,
    });
    setFoundRes({
      incidentQuestion: { id: question.id },
      checkbox: value.map((v) => v.option.name).join(),
      options: value,
    });
  };

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) => question.id === res.incidentQuestion?.id
    );
    if (doesExist) {
      setFoundRes(doesExist);
    } else {
      setFoundRes({
        incidentQuestion: { id: question.id },
        checkbox: "",
        options: [],
      });
    }
  }, []);

  return (
    <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
      <Autocomplete
        disablePortal
        id="select"
        options={sortByKeys(question.incidentQuestionResponses, "ordering")}
        value={foundRes.options}
        onChange={(event, value) => handleChange(value)}
        getOptionLabel={(option) => option.option.name}
        isOptionEqualToValue={(option, value) =>
          option.option.id === value.option.id
        }
        disableClearable
        style={{ width: "100%" }}
        multiple
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ color: "#fff" }}
            variant="standard"
            label={question.name}
          />
        )}
      />
    </Grid>
  );
}
