import React from "react";

import { Typography } from "@mui/material";

import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";

export default function ViewObservations() {
  return (
    <Layout>
      <Typography
        sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
      >
        VIEW OBSERVATIONS.
      </Typography>
      <Table />
    </Layout>
  );
}
