import * as React from "react";

import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";

import { MUTATE_PROJECT } from "../../../../../graphql/mutations/admin/planning-project";
import { GET_PROJECTS } from "../../../../../graphql/queries/admin/planning-project";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import EditProject from "./EditProject";

export default function Row({ row }) {
  const [editProject] = useMutation(MUTATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [active, setActive] = React.useState(row.isActive);
  const [open, setOpen] = React.useState(false);

  const handleIsActiveChange = (event) => {
    setActive(event.target.checked);
    editProject({
      variables: {
        input: { id: Number(row.id), isActive: event.target.checked },
      },
    });
  };

  return (
    <>
      <TableRow hover key={row.id}>
        <TableCell component="th" scope="row">
          {row.name}{" "}
          {row.ownerSiteProjects.length > 0
            ? `[${row.ownerSiteProjects[0].site.name} - ${row.ownerSiteProjects[0].owner.name}]`
            : ""}
        </TableCell>
        <TableCell>
          {moment(new Date(row.timeCreated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell>{row.ownerSiteProjects?.length}</TableCell>
        <TableCell>${row.dollarAmount}</TableCell>
        <TableCell>
          <CustomSwitch
            checked={active}
            name={row.id}
            onChange={(event) => handleIsActiveChange(event)}
          />
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="yellow0"
            onClick={() => setOpen(true)}
          >
            VIEW
          </Button>
        </TableCell>
      </TableRow>
      <EditProject open={open} handleClose={() => setOpen(false)} row={row} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
