import React from "react";

import { Image, Link, Text, View } from "@react-pdf/renderer";
import ExifReader from "exifreader";
import PropTypes from "prop-types";

const width = "150px";

const IncidentImage = ({ img }) => {
  const [exif, setExif] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(img);
      const blob = await res.blob();
      const arrBuff = await blob.arrayBuffer();
      const tags = ExifReader.load(arrBuff);
      setExif(tags);
    }
    fetchData();
  }, []);

  let style;
  const rotVal = exif.Orientation?.value;
  if (rotVal == 1 || rotVal == undefined) {
    style = { width };
  } else if (rotVal == 3) {
    style = { width, transform: "rotate(180deg)" };
  } else if (rotVal == 6) {
    style = { width, transform: "rotate(90deg)" };
  } else if (rotVal == 8) {
    style = { width, transform: "rotate(270deg)" };
  }

  return <Image style={{ ...style, marginTop: "10px" }} src={img} />;
};

IncidentImage.propTypes = {
  img: PropTypes.string,
};

const getPreviewIcon = (fileObject) => {
  if (
    fileObject.file &&
    (fileObject.file.includes("png") ||
      fileObject.file.includes("jpg") ||
      fileObject.file.includes("jpeg"))
  ) {
    return (
      <Link
        key={fileObject.id}
        src={fileObject.file}
        style={{
          color: "white",
          fontSize: "14px",
          margin: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width,
          minHeight: "150px",
        }}
      >
        <IncidentImage key={fileObject.id} img={fileObject.file} />
      </Link>
    );
  }

  return (
    <>
      {fileObject.file && (
        <Link
          key={fileObject.id}
          src={fileObject.file}
          style={{ color: "white", fontSize: "14px", margin: "10px" }}
        >
          <Text>
            {fileObject.file.split("/")[fileObject.file.split("/").length - 1]}
          </Text>
        </Link>
      )}
    </>
  );
};

export default function Responses({ id, i }) {
  const responses = i.incidentTopicResponses.find(
    (resp) => resp.incidentTopic.id === id
  );

  return (
    <View>
      {responses && (responses.note !== "" || responses.files.length > 0) ? (
        <View
          style={{
            marginBottom: "20px",
            backgroundColor: "#8297A0",
            color: "white",
            padding: "10px",
          }}
        >
          <Text style={{ fontSize: "14px", fontFamily: "Helvetica-Bold" }}>
            Note:
          </Text>
          <Text style={{ fontSize: "14px", margin: "10px 0px" }}>
            {responses.note}
          </Text>
          {responses.files.length > 0 && (
            <View style={{ margin: "20px 0px" }}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Helvetica-Bold",
                  margin: "10px 0px",
                }}
                wrap={false}
              >
                Files:
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {responses.files.map((f) => getPreviewIcon(f))}
              </View>
            </View>
          )}
        </View>
      ) : (
        <View>
          <Text> </Text>
        </View>
      )}
    </View>
  );
}

Responses.propTypes = {
  id: PropTypes.string,
  i: PropTypes.object,
};
