import * as React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { viewObservationFiltersVar } from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import {
  GET_CONTRACTORS_AT_OWNER_SITE_PROJECT,
  GET_USERNAMES,
  GET_OUTCOMES,
  GET_SITES,
} from "../../../../graphql/queries";
import sortByKeys from "../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  clearFilters,
  onFilter,
}) {
  const { updateKeyViewObservationFilter } = observationMutations;
  const filters = useReactiveVar(viewObservationFiltersVar);
  const online = useReactiveVar(onlineVar);
  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });
  const { data: contractors } = useQuery(
    GET_CONTRACTORS_AT_OWNER_SITE_PROJECT,
    { fetchPolicy: online ? "network-only" : "cache-only" }
  );
  const { data: usernames } = useQuery(GET_USERNAMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: getOutcomes } = useQuery(GET_OUTCOMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const removeDuplicateContractors = (arr) => {
    const uniqueIds = [];
    const filtered = arr.filter((c) => {
      const isDuplicate = uniqueIds.includes(c.contractor.id);
      if (!isDuplicate) {
        uniqueIds.push(c.contractor.id);
        return true;
      }
      return false;
    });
    return filtered;
  };

  const getContractors = () => {
    if (filters.site && filters.site.owner.name !== "") {
      return contractors?.ownerSiteProjectContractors
        ? contractors.ownerSiteProjectContractors.filter(
            (c) =>
              c.ownerSiteProject.owner?.id === filters.site.owner.id &&
              c.ownerSiteProject.site?.id === filters.site.site.id
          )
        : [];
    }
    return contractors?.ownerSiteProjectContractors
      ? removeDuplicateContractors(contractors.ownerSiteProjectContractors)
      : [];
  };

  const filter = () => {
    onFilter();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-site"
              options={
                sites?.ownerSiteProjects
                  ? sortByKeys(sites.ownerSiteProjects, "site.name")
                  : []
              }
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site || { owner: { name: "" } }}
              onChange={(event, newValue) =>
                updateKeyViewObservationFilter("site", newValue)
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-contractor"
              options={sortByKeys(getContractors(), "contractor.name")}
              getOptionLabel={(option) => option.contractor?.name}
              value={
                filters.contractor || {
                  contractor: { name: "" },
                }
              }
              onChange={(event, newValue) =>
                updateKeyViewObservationFilter("contractor", newValue)
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.contractor.id}>
                    {option.contractor?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Contractor"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-outcomes"
              options={
                getOutcomes?.outcomes
                  ? sortByKeys(getOutcomes.outcomes, "name")
                  : []
              }
              getOptionLabel={(option) => {
                return option.name;
              }}
              value={filters.outcomes || []}
              onChange={(event, newValue) =>
                updateKeyViewObservationFilter("outcomes", newValue || [])
              }
              multiple
              limitTags={4}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Outcome"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-planned"
              options={["Planned", "Unplanned"]}
              getOptionLabel={(option) => {
                return option;
              }}
              value={filters.status || ""}
              onChange={(event, newValue) =>
                updateKeyViewObservationFilter("status", newValue || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Planned Status"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "5px" }}>
            <Autocomplete
              id="filter-usernames"
              options={
                usernames?.users ? sortByKeys(usernames.users, "username") : []
              }
              getOptionLabel={(option) => {
                return option.username;
              }}
              value={filters.usernames || []}
              onChange={(event, newValue) =>
                updateKeyViewObservationFilter("usernames", newValue || [])
              }
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.username}
                  </li>
                );
              }}
              multiple
              limitTags={4}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Submitter"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.afterDate || null}
              onChange={(date) =>
                updateKeyViewObservationFilter("afterDate", date)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "5px" }}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MMM DD, yyyy"
              value={filters.beforeDate || null}
              onChange={(date) =>
                updateKeyViewObservationFilter("beforeDate", date)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%" }}
                  variant="standard"
                  {...params}
                />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};
