const sortByKeys = (array, ...keys) => {
  return [...array].sort((a, b) => {
    if (keys.length === 0) {
      // Default sorting by a and b values directly
      return a > b ? 1 : a < b ? -1 : 0;
    }

    for (let key of keys) {
      let aValue = key.split(".").reduce((obj, k) => obj[k], a);
      let bValue = key.split(".").reduce((obj, k) => obj[k], b);

      // Convert to lowercase if values are strings
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (aValue > bValue) return 1;
      if (aValue < bValue) return -1;
    }
    return 0;
  });
};

export default sortByKeys;
