import React from "react";

import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import { styles } from "./styles";

export default function ActionStep({ action, ha }) {
  const getUniqueRisks = (arr) =>
    arr.reduce((results, item) => {
      if (
        item.arm.r &&
        !results.some(
          (i) =>
            i.arm.r?.name === item.arm.r.name && i.arm.r?.id === item.arm.r.id
        )
      ) {
        results.push(item);
      }
      return results;
    }, []);

  const findRisks = (a) => {
    const allRisks = ha.haArms.filter(
      (oa) => oa.arm.a.name === a.name && oa.arm.a.id === a.id
    );

    return getUniqueRisks(allRisks);
  };

  const findMitigators = (ar) => {
    const allMitigators = ha.haArms.filter(
      (oa) =>
        oa.arm.r?.name === ar.r?.name &&
        oa.arm.r?.id === ar.r?.id &&
        oa.arm.a.name === ar.a.name &&
        oa.arm.a.id === ar.a.id
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.arm.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators);
  };

  return (
    <View style={styles.row}>
      <View style={styles.armSectionHeader}>
        <Text style={[styles.text, { textAlign: "center" }]} wrap={false}>
          {action.arm.a.name}
        </Text>
      </View>
      <View style={[styles.column, { width: "66.6%" }]}>
        {findRisks(action.arm.a).length === 0 ? (
          <View style={styles.armSection}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
        ) : (
          findRisks(action.arm.a)
            .sort((a, b) => {
              const aName = a.arm.r.name;
              const bName = b.arm.r.name;

              const aExpected = a.arm.isExpected;
              const bExpected = b.arm.isExpected;

              if ((aExpected && bExpected) || (!aExpected && !bExpected)) {
                return aName > bName ? 1 : -1;
              }
              if (aExpected && !bExpected) return -1;
              if (!aExpected && bExpected) return 1;
            })
            .map((risk, index) => {
              return (
                <View key={risk.arm.r?.name} style={[styles.row]}>
                  <View style={[styles.armSection, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.text,
                        {
                          textAlign: "center",
                        },
                      ]}
                      wrap={false}
                    >
                      {risk.arm.r?.name}
                    </Text>
                    {index === findRisks(action.arm.a).length - 1 && (
                      <Text style={styles.text}> </Text>
                    )}
                  </View>
                  <View style={[styles.column, { width: "50%" }]}>
                    {findMitigators(risk.arm).length === 0 ? (
                      <View style={styles.armSection}>
                        <Text style={styles.text}> </Text>
                        {index === findRisks(action.arm.a).length - 1 && (
                          <Text style={styles.text}> </Text>
                        )}
                      </View>
                    ) : (
                      sortByKeys(findMitigators(risk.arm), "arm.m.name").map(
                        (mit) => {
                          return (
                            <View
                              key={mit.arm.m.name}
                              style={styles.armSection}
                            >
                              <Text style={styles.text} wrap={false}>
                                {mit.arm.m.name}
                              </Text>
                              {findMitigators(risk.arm).length === 1 &&
                                index ===
                                  findRisks(action.arm.a).length - 1 && (
                                  <Text style={styles.text}> </Text>
                                )}
                            </View>
                          );
                        }
                      )
                    )}
                  </View>
                </View>
              );
            })
        )}
      </View>
    </View>
  );
}

ActionStep.propTypes = {
  action: PropTypes.object,
  ha: PropTypes.object,
};
