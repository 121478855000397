import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { INCIDENT_EDIT } from "../../../graphql/mutations/investigation";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomDialog } from "../../CustomStyles/dialog";

const formatQuestionResponses = (arr) => {
  return arr.map((r) => {
    if (r.multipleChoice) {
      return {
        incidentQuestion: Number(r.incidentQuestion.id),
        options: [Number(r.multipleChoice)],
      };
    } else if (r.checkbox) {
      return {
        incidentQuestion: Number(r.incidentQuestion.id),
        options: r.options ? r.options.map((o) => Number(o.option?.id)) : [],
      };
    } else if (r.boolean) {
      return {
        incidentQuestion: Number(r.incidentQuestion.id),
        boolean: r.boolean === "true" || r.boolean === true,
      };
    } else {
      return {
        date: r.date,
        dateRanges: [],
        textBox: r.textBox,
        textInput: r.textInput,
        time: r.time,
        incidentQuestion: Number(r.incidentQuestion.id),
        ...(r.options
          ? {
              options: r.options.map((o) => Number(o.option ? o.option.id : o)),
            }
          : null),
      };
    }
  });
};

const getInput = (report) => {
  return {
    incident: report.incident.id,
    arms: [],
    ha: report.ha ? Number(report.ha.id) : null,
    ownerSiteProject: Number(report.ownerSiteProject.id),
    date: report.date,
    time: report.time,
    employeePhoneNumber: report.employeePhoneNumber
      ? report.employeePhoneNumber
      : "",
    employeeName: report.employeeName ? report.employeeName : "",
    employeeJobTitle: report.employeeJobTitle ? report.employeeJobTitle : "",
    employeeDob: report.employeeDob ? report.employeeDob : null,
    injuryDate: report.injuryDate ? report.injuryDate : null,
    injuryTime: report.injuryTime ? report.injuryTime : null,
    topicResponses: report.incidentTopicResponses.map((r) => ({
      incidentTopic: Number(r.incidentTopic.id),
      note: r.note,
    })),
    questionResponses: formatQuestionResponses(
      report.incidentQuestionResponses
    ),
    removeTopicFiles: [],
    removeQuestionFiles: [],
    isCompleted: true,
  };
};

export default function CloseIncident() {
  const [editIncidentMutation] = useMutation(INCIDENT_EDIT, {
    onCompleted(data) {
      if (data.incidentEdit.incidentReport) {
        onCompletedFunc("Incident investigation was closed successfully!");
      } else {
        onErrorFunc(
          "There was an error when editing your incident investigation."
        );
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [openClose, setOpenClose] = React.useState(false);

  const closeIncident = () => {
    setOpenClose(false);
    const report = data.incidentReport;
    editIncidentMutation({
      variables: {
        id: Number(report.id),
        input: {
          ...getInput(report),
          isCompleted: true,
        },
      },
    });
  };

  return (
    <>
      <Button
        style={{
          width: "fit-content",
          backgroundColor: "red",
          marginLeft: "20px",
        }}
        variant="contained"
        onClick={() => setOpenClose(true)}
      >
        CLOSE
      </Button>

      <CustomDialog open={openClose} onClose={() => setOpenClose(false)}>
        <DialogTitle>Close Incident Investigation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to close this investigation? Once closed, it
            cannot be edited or reopened.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              width: "fit-content",
              backgroundColor: "red",
              color: "white",
            }}
            onClick={closeIncident}
          >
            CLOSE
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenClose(false)}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
