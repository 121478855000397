import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_COMPANIES } from "../../../../graphql/queries";
import sortByKeys from "../../../../utils/sortByKeys";

export default function Company({ company, setCompany }) {
  // Queries
  const { data, loading } = useQuery(GET_COMPANIES);

  const handleChange = (event, newValue) => {
    setCompany(newValue || { name: "" });
  };

  return (
    <Autocomplete
      id="select-company"
      value={company}
      options={data ? sortByKeys(data.companies, "name") : []}
      getOptionLabel={(option) => option.name}
      loading={loading}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Filter Observers by Company:"
        />
      )}
    />
  );
}
