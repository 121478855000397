import React from "react";

import { Document } from "@react-pdf/renderer";

import ARMSection from "./ARMSection";
import FirstPage from "./FirstPage";
import ImagesSection from "./ImagesSection";

export const PDFDocument = (observation, arms) => {
  return (
    <Document pageMode="fullScreen" title="Observation Report" author="NIXN">
      <FirstPage observation={observation} />
      <ARMSection observation={observation} arms={arms.companyArmsView} />
      <ImagesSection observation={observation} />
    </Document>
  );
};
