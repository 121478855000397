import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { observationMutations } from "../../../../graphql/mutations";
import sortByKeys from "../../../../utils/sortByKeys";

export default function Projects({ type, data, loading, onEdit }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Local Variables
  const [projects, setProjects] = React.useState([]);

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const handleChange = (event, newValue) => {
    updateObsKeyFunction("project", newValue);
    onEdit();
  };

  React.useEffect(() => {
    if (form.ha && form.ha.haOwnerSiteProjects) {
      setProjects(
        form.ha.haOwnerSiteProjects.map((osp) => osp.ownerSiteProject.project)
      );
    } else {
      if (data) {
        if (data.filterProjects) {
          setProjects(data.filterProjects.map((osp) => osp.project));
        } else setProjects(data.ownerSiteProjects.map((osp) => osp.project));
      }
    }
  }, [data, form.ha]);

  React.useEffect(() => {}, [form.project]);

  return (
    <Autocomplete
      id="obs-select-projects"
      options={sortByKeys(projects, "name")}
      value={form.project || { name: "" }}
      getOptionLabel={(option) => {
        return option.name;
      }}
      onChange={handleChange}
      disabled={!form.site}
      loading={loading}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          value={params}
          variant="standard"
          label="Project:"
        />
      )}
    />
  );
}
