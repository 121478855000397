import React from "react";

import { Autocomplete } from "@mui/material";

import { useLazyQuery } from "@apollo/client";

import { GET_TEMPLATE_PAGE_OSPS } from "../../../../graphql/queries/admin/planning-template";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import ListboxComponent from "./VirtualizedListBox";

export default function SelectOwnerSiteProject({ open, osp, setOsp }) {
  const [getOSP, { data: osps, loading: loadingOSP }] = useLazyQuery(
    GET_TEMPLATE_PAGE_OSPS
  );

  React.useEffect(() => {
    if (open) {
      getOSP();
    }
  }, [open]);

  const ospList = osps ? osps.templatePageOsps : [];

  const getOptionLabelFunction = (option) => {
    if (!option.site && !option.project) {
      return `Company: ${option.owner?.name}`;
    } else if (option.site && !option.project) {
      return `Site: ${option.site?.name} [${option.owner?.name}]`;
    } else
      return `Project: ${option.site?.name} [${option.owner?.name}] - ${option.project?.name}`;
  };

  return (
    <Autocomplete
      id="select-osp"
      options={[...ospList].sort((a, b) => {
        if (!a.site && b.site) {
          return -1;
        }
        if (a.site && !b.site) {
          return 1;
        }
        if (!a.project && b.project) {
          return -1;
        }
        if (a.project && !b.project) {
          return 1;
        }
        if (!a.site && !a.project && !b.site && !b.project) {
          return a.owner.name > b.owner.name ? 1 : -1;
        }
        if (a.site && !a.project && b.site && !b.project) {
          return a.site.name > b.site.name ? 1 : -1;
        }
        if (a.project && b.project) {
          return a.project.name > b.project.name ? 1 : -1;
        }
        return 0;
      })}
      value={osp}
      ListboxComponent={ListboxComponent}
      onChange={(event, value) => setOsp(value)}
      getOptionLabel={getOptionLabelFunction}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableClearable
      multiple
      loading={loadingOSP}
      renderOption={(props, option, state) => [props, option, state]}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          style={{ color: "#fff" }}
          variant="standard"
          label={"Company, Site or Project:"}
        />
      )}
    />
  );
}
