import React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

import PropTypes from "prop-types";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({ fileObjects, classes }) {
  const getPreviewIcon = (fileObject, classes) => {
    if (
      fileObject.file &&
      (fileObject.file.includes("png") ||
        fileObject.file.includes("jpg") ||
        fileObject.file.includes("jpeg"))
    ) {
      return (
        <a
          href={fileObject.file}
          target="_blank"
          rel="noreferrer"
          download
          style={{ color: "white" }}
        >
          <img className={classes.image} src={fileObject.file} alt="Incident" />
          <Typography>
            {fileObject.file.split("/")[fileObject.file.split("/").length - 1]}
          </Typography>
        </a>
      );
    }

    return (
      <>
        {fileObject.file ? (
          <a
            href={fileObject.file}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {
                fileObject.file.split("/")[
                  fileObject.file.split("/").length - 1
                ]
              }
            </Typography>
          </a>
        ) : (
          <>
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {
                fileObject.file.split("/")[
                  fileObject.file.split("/").length - 1
                ]
              }
            </Typography>
          </>
        )}
      </>
    );
  };

  return (
    <Grid container>
      {fileObjects?.map((fileObject) => (
        <Grid key={`topic-${fileObject.id}`} item xs={12} container>
          <Grid item xs={12}>
            <Typography variant="h5">
              {fileObject.incidentTopic.name}
            </Typography>
          </Grid>
          {fileObject.files.map((f) => (
            <Grid
              item
              xs={3}
              key={`file-${f.id}`}
              className={classes.imageContainer}
            >
              {getPreviewIcon(f, classes)}
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

PreviewList.propTypes = {
  fileObjects: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
