import * as React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Skeleton, Typography, Button, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useQuery } from "@apollo/client";

import { COMPANY_DASHBOARD } from "../../../graphql/queries/admin/company";
import sortByKeys from "../../../utils/sortByKeys";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CompanySelect({ company, setOpenCreate }) {
  const navigate = useNavigate();

  // Get all companies avaliable to user
  const { data: getCompanies } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: "network-only",
  });

  // State for popover menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // When new company is selected
  const onSelect = (id) => {
    navigate(`/admin/arm/${id}`);
    setAnchorEl(null);
  };

  // Render list of companies
  // TODO: Make avatar for companies without logo prettier??
  const renderCompanyMenuItems = () => {
    if (getCompanies?.companies) {
      return sortByKeys(getCompanies.companies, "name").map((c) => {
        return (
          <MenuItem onClick={() => onSelect(c.id)} key={c.id}>
            <Avatar
              alt={c.name}
              src={`data:image/jpg;base64,${c.logoBase64}`}
            />
            {c.name}
          </MenuItem>
        );
      });
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={10} container>
        <Typography onClick={handleClick} sx={{ ...pageTitleStyles }}>
          <span style={{ fontSize: "1.5rem" }}>ARM ADMINISTRATION:</span>{" "}
          <span
            style={{
              color: "rgb(255, 183, 0)",
              fontSize: "3rem",
            }}
          >
            {company && company.company ? (
              company.company?.name.toUpperCase()
            ) : (
              <Skeleton
                variant="rectangular"
                sx={{ display: "inline-block" }}
                width={400}
                height={50}
              />
            )}
          </span>
        </Typography>

        {getCompanies && getCompanies.companies.length > 0 && (
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon sx={{ fontSize: "xxx-large", color: "#8297A0" }} />
          </IconButton>
        )}
      </Grid>

      <Menu
        anchorEl={anchorEl}
        id="company-select"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {renderCompanyMenuItems()}
      </Menu>

      <Button
        variant="contained"
        color="yellow0"
        sx={{ mr: 1 }}
        onClick={() => setOpenCreate(true)}
      >
        CREATE ARM
      </Button>
    </React.Fragment>
  );
}
