import { gql } from "@apollo/client";

// VIEW

export const GET_OBSERVATIONS = gql`
  query getObservations(
    $first: Int
    $after: String
    $name: String
    $afterDate: Date
    $beforeDate: Date
    $outcome: [ID]
    $planned: Boolean
    $owner: ID
    $site: ID
    $contractor: ID
    $submitter: [ID]
  ) {
    observations(
      isActive: true
      first: $first
      after: $after
      fName: $name
      fAfterDate: $afterDate
      fBeforeDate: $beforeDate
      fOutcome: $outcome
      fPlanned: $planned
      fOwner: $owner
      fSite: $site
      fContractor: $contractor
      fSubmitter: $submitter
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          obsId
          id
          name
          date
          time
          submitter {
            id
            username
          }
          ha {
            name
            date
            haId
          }
          observationOutcomes(isActive: true) {
            outcome {
              name
            }
          }
          observationUsers(isActive: true) {
            user {
              username
              id
              firstName
              lastName
            }
            notification
            notified
          }
          contractor {
            name
          }
          manualCheck
          automaticCheck
          ownerSiteProject {
            owner {
              id
              name
            }
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_OBSERVATION = gql`
  query getObservation($id: Int!) {
    observation(id: $id) {
      id
      timeCreated
      date
      time
      name
      comment
      submitter {
        id
        username
        company {
          id
          logoBase64
        }
        supervisedBy {
          isActive
          supervisor {
            id
            username
            firstName
            lastName
          }
        }
      }
      manualCheck
      automaticCheck
      raScore
      raScoreMax
      raScoreMin
      raScoreCorr
      outcomeExpected
      outcomeInjury
      outcomeNearMiss
      dollarAmount
      observationOutcomes(isActive: true) {
        outcome {
          name
          id
        }
      }
      observationUsers(isActive: true) {
        user {
          username
          id
          firstName
          lastName
        }
        notification
        notified
      }
      observationCrewMembers(isActive: true) {
        crewMember {
          phoneNumber
          id
          firstName
          lastName
        }
      }
      ha {
        haId
        id
        name
        haArms(isActive: true) {
          arm {
            id
            a {
              id
              name
            }
            r {
              id
              name
            }
            m {
              id
              name
            }
          }
        }
      }
      ownerSiteProject {
        id
        owner {
          name
          id
        }
        site {
          name
          id
        }
        project {
          name
          id
        }
      }
      contractor {
        name
        id
      }
      observationArms {
        isCorrection
        isRootCause
        isActive
        arm {
          id
          isExpected
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
        observationArmMediaFiles(isActive: true) {
          id
          mediaFile {
            id
            file
          }
          observationArm {
            isCorrection
            isRootCause
            isActive
            arm {
              id
              isExpected
              a {
                id
                name
              }
              r {
                id
                name
              }
              m {
                id
                name
              }
            }
          }
        }
      }
      observationMediaFiles(isActive: true) {
        id
        mediaFile {
          id
          file
        }
      }
    }
  }
`;

// CREATE/EDIT

export const GET_OBS_CORRECTION_USERS = gql`
  query getObservationCorrectionUsersSupervisors($ospIds: [Int]) {
    users(
      ownerSiteProjectUser_OwnerSiteProject_Id_In: $ospIds
      ownerSiteProjectUser_IsActive: true
    ) {
      username
      firstName
      lastName
      id
      image
      company {
        name
      }
    }

    supervisors: users(
      supervise_OwnerSiteProject_Id_In: $ospIds
      supervise_IsActive: true
    ) {
      username
      firstName
      lastName
      id
      image
      company {
        name
      }
    }
  }
`;

export const GET_OWNER_OSP = gql`
  query getOSP($ownerId: Int) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Isnull: true
      project_Isnull: true
    ) {
      id
    }
  }
`;

export const GET_SITE_OSP = gql`
  query getOSP($ownerId: Int, $siteId: Int) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Id: $siteId
      project_Isnull: true
    ) {
      id
    }
  }
`;

// OFFLINE

export const GET_FILTERED_CONTRACTORS_OBS = gql`
  query contractors($siteId: Int!, $ownerId: Int!) {
    filterContractors(siteId: $siteId, ownerId: $ownerId) @client {
      name
      id
    }
  }
`;
