import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  Autocomplete,
  TextField,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { onlineVar } from "../../../graphql/localVariables/user";
import { haMutations } from "../../../graphql/mutations";
import { GET_FILTERED_CREWMEMBERS } from "../../../graphql/queries";
import { GET_OFFLINE_FILTERED_CREWMEMBERS } from "../../../graphql/queries/ha";
import sortByGetFunction from "../../../utils/sortByGetFunction";
import SignatureView from "./SignatureView";
import { useStyles } from "./styles";

export default function CrewMemberView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const online = useReactiveVar(onlineVar);
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const [getFilteredCrewMembers, { loading }] = useLazyQuery(
    GET_FILTERED_CREWMEMBERS,
    {
      onCompleted(data) {
        setCrewmembers(
          data.ownerSiteProjectCrewMembers.map((c) => c.crewMember)
        );
      },
    }
  );
  const [getOfflineFilteredCrewMembers, { loading: loadingOffline }] =
    useLazyQuery(GET_OFFLINE_FILTERED_CREWMEMBERS, {
      onCompleted(data) {
        setCrewmembers(data.filterCrewMembers.map((c) => c.crewMember));
      },
    });

  const classes = useStyles()();
  const matches = useMediaQuery("(min-width:380px)");

  const [crewmembers, setCrewmembers] = React.useState([]);

  React.useEffect(() => {
    if (updateHA.siteOSPId) {
      if (online) {
        getFilteredCrewMembers({
          variables: {
            ospId: Number(updateHA.siteOSPId),
          },
        });
      } else {
        getOfflineFilteredCrewMembers({
          variables: { ospId: Number(updateHA.siteOSPId) },
        });
      }
    }
  }, [updateHA.siteOSPId]);

  React.useEffect(() => {}, [updateHA.crewMembers]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      const prev = updateHA.crewMembers ? updateHA.crewMembers : [];
      updateHAFunction("crewMembers", [...prev, newValue]);
    }
    setCrew({ firstName: "", lastName: "" });
  };

  const removeCrewMember = (member) => {
    updateHAFunction(
      "crewMembers",
      updateHA.crewMembers.filter((c) => c.id !== member.id)
    );
  };
  const CrewMember = ({ member }) => {
    const [showSignature, setShowSignature] = React.useState(false);

    const replaceSignature = (sig) => {
      updateHAFunction(
        "crewMembers",
        updateHA.crewMembers.map((c) => {
          if (c.id === member.id) {
            return {
              ...c,
              replace: true,
              signatureBase64: sig,
              lastUpdated: new Date(),
            };
          } else return c;
        })
      );
    };

    return (
      <Grid
        container
        key={member.id}
        style={{
          padding: ".25em",
        }}
      >
        <Grid
          item
          xs={4}
          style={{
            overflowWrap: "break-word",
          }}
        >
          <Typography>
            {member.firstName} {member.lastName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            textAlign: "end",
          }}
        >
          {matches ? (
            member.signatureBase64 && member.signatureBase64 !== "" ? (
              <Button
                size="small"
                className={classes.crewMemberButtons}
                variant="contained"
                color="primary"
                onClick={() => setShowSignature((prev) => !prev)}
              >
                Signed {moment(member.lastUpdated).format("YYYY-MM-DD")}
              </Button>
            ) : (
              <Button
                size="small"
                className={classes.crewMemberButtons}
                color="secondary"
                variant="contained"
                onClick={() => setShowSignature((prev) => !prev)}
              >
                Add Signature
              </Button>
            )
          ) : member.signatureBase64 && member.signatureBase64 !== "" ? (
            <Tooltip title="Add Signature">
              <IconButton
                className={classes.iconButtonSecondary}
                disableRipple
                onClick={() => setShowSignature((prev) => !prev)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Signed">
              <IconButton
                className={classes.iconButtonPrimary}
                disableRipple
                onClick={() => setShowSignature((prev) => !prev)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Remove">
            <IconButton onClick={() => removeCrewMember(member)}>
              <CloseIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        {showSignature && (
          <SignatureView
            signature={member.signatureBase64}
            setShowSignature={setShowSignature}
            replaceSignature={replaceSignature}
            showSignature={showSignature}
          />
        )}
      </Grid>
    );
  };

  const unselectedCrewmembers = () =>
    crewmembers.filter(
      (c) => updateHA.crewMembers.findIndex((haCrew) => haCrew?.id === c.id) < 0
    );

  const [crew, setCrew] = React.useState({ firstName: "", lastName: "" });

  return (
    <Grid item key={"actions"} xs={12} md={6} className={classes.borderedBox}>
      {(online && loading) || (!online && loadingOffline) ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Autocomplete
            disablePortal
            id="select-crewmember"
            value={crew}
            options={sortByGetFunction(unselectedCrewmembers(), (item) =>
              `${item.firstName}${item.lastName}`.toLowerCase()
            )}
            getOptionLabel={(option) =>
              option.firstName !== ""
                ? `${option.firstName} ${option.lastName}`
                : ""
            }
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li
                {...props}
                key={`${option.firstName}-${option.lastName}-${option.id}`}
              >
                {option.firstName} {option.lastName}
              </li>
            )}
            filterOptions={createFilterOptions({
              trim: true,
              stringify: (option) => `${option.firstName} ${option.lastName}`,
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Add Crew Members:"}
              />
            )}
          />
          <Grid
            container
            style={{
              marginTop: "20px",
              maxHeight: "350px",
              overflow: "auto",
            }}
          >
            {updateHA?.crewMembers &&
              sortByGetFunction(updateHA.crewMembers, (item) =>
                `${item.firstName}${item.lastName}`.toLowerCase()
              ).map((c) => <CrewMember key={c.id} member={c} />)}
          </Grid>
        </>
      )}
    </Grid>
  );
}
