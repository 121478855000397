import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function IncidentType({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (event, newValue) => {
    updateObsKeyFunction("type", newValue ?? "");
  };

  return (
    <Autocomplete
      id="inc-select-type"
      value={form.type}
      options={["Injury", "Property Damage", "Near Miss"]}
      getOptionLabel={(option) => {
        return option;
      }}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="What type of incident occurred?"
        />
      )}
    />
  );
}
