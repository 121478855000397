import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    width: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Helvetica",
  },
  header: { fontSize: "12px", fontFamily: "Helvetica-Bold" },
  formSection: { width: "147.3px", border: "1px solid black" },
  armSectionHeader: { width: "185px" },
  column: { display: "flex", flexDirection: "column" },
  row: { display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
});
