import React from "react";

import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

import styles from "./styles";

export default function ReviewPage({ auditSubmission }) {
  const d = new Date(auditSubmission.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  let startTime = moment();
  if (auditSubmission.time) {
    const timeArr = auditSubmission.time.split(":");
    startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
  }

  const supervisors = (() => {
    const activeSupervisors = auditSubmission.submitter?.supervisedBy?.filter(
      (s) => s.isActive
    );

    if (activeSupervisors.length > 0) {
      return activeSupervisors.map((s) => s.supervisor.username).join(", ");
    }
    return " ";
  })();

  return (
    <Page size="A4">
      <View
        style={{
          borderTop: "3px solid black",
          margin: "30px 20px 20px 20px",
        }}
      >
        <Text style={{ marginTop: "20px" }}>Audit Approval/Review</Text>
        <View
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Supervisor:</Text>
            <Text style={styles.mainText}>{supervisors}</Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Who Completed Audit:</Text>
            <Text style={styles.mainText}>
              {auditSubmission.submitter.firstName}{" "}
              {auditSubmission.submitter.lastName}{" "}
              {`(${auditSubmission.submitter.username})`}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Date:</Text>
            <Text style={styles.mainText}>
              {auditSubmission.date
                ? moment(convertedDate).format("MM/DD/YYYY")
                : ""}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={styles.labelText}>Time:</Text>
            <Text style={styles.mainText}>
              {auditSubmission.time
                ? moment(new Date(startTime)).format("hh:mm A")
                : ""}
            </Text>
          </View>
          {auditSubmission.auditSubmissionUsers.length > 0 && (
            <View style={{ width: "100%" }}>
              <Text style={[styles.labelText, { marginTop: "20px" }]}>
                Other Audit Contributors:
              </Text>
              <Text style={{ fontSize: "10px", padding: "10px" }}>
                {auditSubmission.auditSubmissionUsers
                  .map(
                    (user) =>
                      `${user.user.firstName} ${user.user.lastName} (${user.user.username})`
                  )
                  .join(", ")}
              </Text>
            </View>
          )}
        </View>
      </View>
    </Page>
  );
}

ReviewPage.propTypes = {
  auditSubmission: PropTypes.object,
};
