const sortByGetFunction = (array, getValue) => {
  return [...array].sort((a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);
    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;

    return 0;
  });
};

export default sortByGetFunction;
