import React from "react";

import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import Question from "./Question";
import Responses from "./Responses";
import styles from "./styles";

export default function Topic({ topic, i }) {
  if (
    topic.name === "Incident Classification - US (OSHA)" &&
    i.type !== "Injury"
  )
    return (
      <View>
        <Text> </Text>
      </View>
    );

  return (
    <View style={{ width: "100%" }}>
      {topic.name !== "Incident Details - US (OSHA)" &&
        topic.name !== "General" && (
          <Text
            style={[
              styles.header,
              {
                borderTop: "3px solid grey",
                paddingTop: "10px",
                fontSize: "16px",
              },
            ]}
          >
            {topic.name.toUpperCase()}
          </Text>
        )}
      {topic.name === "Incident Details - US (OSHA)" ? (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {sortByKeys(topic.incidentQuestions, "ordering").map((q) => (
            <View
              key={q.id}
              style={[
                styles.armSectionHeader,
                { width: "100%", margin: "20px 0px" },
              ]}
            >
              <Text
                style={[
                  styles.header,
                  { marginBottom: "10px", paddingRight: "10px" },
                ]}
              >
                {q.name}
              </Text>
              <View>
                {i.incidentQuestionResponses.find(
                  (resp) => resp.incidentQuestion.id === q.id
                ) ? (
                  <Question
                    question={i.incidentQuestionResponses.find(
                      (resp) => resp.incidentQuestion.id === q.id
                    )}
                  />
                ) : (
                  <View>
                    <Text> </Text>
                  </View>
                )}
              </View>
            </View>
          ))}
        </View>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {sortByKeys(topic.incidentQuestions, "ordering").map((q) => (
            <View
              key={q.id}
              style={[
                styles.armSectionHeader,
                {
                  width: q.name === "What Happened?" ? "100%" : "48%",
                  margin: "10px 0px",
                  marginRight: "5px",
                },
              ]}
            >
              <Text
                style={[
                  styles.header,
                  { marginBottom: "10px", paddingRight: "10px" },
                ]}
              >
                {q.name}
              </Text>
              <View>
                {i.incidentQuestionResponses.find(
                  (resp) => resp.incidentQuestion.id === q.id
                ) ? (
                  <Question
                    question={i.incidentQuestionResponses.find(
                      (resp) => resp.incidentQuestion.id === q.id
                    )}
                  />
                ) : (
                  <View>
                    <Text> </Text>
                  </View>
                )}
              </View>
            </View>
          ))}
        </View>
      )}

      <Responses id={topic.id} i={i} />
    </View>
  );
}

Topic.propTypes = {
  topic: PropTypes.object,
  i: PropTypes.object,
};
