import React from "react";

import { Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import removeDuplicates from "../../../../utils/removeDuplicates";
import sortByKeys from "../../../../utils/sortByKeys";
import { styles } from "./styles";

export default function MissedMitigatorsPage({ ha, arms }) {
  const [missedMitigators, setMissedMitigators] = React.useState([]);

  React.useEffect(() => {
    const allRisks = ha.haArms.filter((arm) => arm.arm.r && !arm.arm.m);
    const allMit = arms.companyArmsView.map((carm) => carm.arm);

    // Get all mitigators that match action-risks in the HA arm
    const potentialMit = removeDuplicates(
      allMit.filter(
        (arm) =>
          allRisks.findIndex(
            (r) =>
              Number(r.arm.a.id) === Number(arm.a.id) &&
              Number(r.arm.r.id) === Number(arm.r.id)
          ) >= 0
      ),
      "id"
    );

    const haMitigators = ha.haArms
      .filter((haArm) => haArm.arm.m)
      .map((m) => Number(m.arm.id));

    // Get mitigators not included in the HA arm
    const missingMit = potentialMit.filter(
      (m) => !haMitigators.includes(Number(m.id))
    );

    setMissedMitigators(missingMit);
  }, []);

  return (
    <Page size="A4" orientation="landscape">
      <View
        style={{
          border: "3px solid black",
          margin: "20px",
          padding: "10px",
        }}
      >
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: "14px" }}>
          Missed Mitigators:
        </Text>
        {sortByKeys(missedMitigators, "r.name").map((m) => (
          <Text key={m.id} style={{ fontSize: "12px", padding: "5px 0px" }}>
            {m.m.name} [{m.r.name}]
          </Text>
        ))}
      </View>
      <View
        style={{ display: "flex", margin: "20px", flexDirection: "row" }}
        wrap={false}
      >
        <View
          style={{
            width: "50%",
            padding: "20px",
            border: "3px solid black",
          }}
        >
          <View style={{ marginBottom: "10px" }}>
            <Text style={styles.sectionLabel}>Comments:</Text>
            {ha.comment ? (
              <Text
                style={{
                  fontSize: "12px",
                  borderBottom: "1px solid grey",
                }}
              >
                {ha.comment}
              </Text>
            ) : null}
          </View>
        </View>
        <View
          style={{
            width: "50%",
            padding: "20px",
            border: "3px solid black",
            borderLeft: "none",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <Text style={styles.sectionLabel}>Did an incident occur?</Text>
            <Text
              style={{
                fontSize: "12px",
                marginLeft: "10px",
              }}
            >
              {ha.hasInjury ? "YES" : "NO"}
            </Text>
          </View>
          <View style={{ width: "100%", marginTop: "10px" }}>
            {ha.injuryText && ha.injuryText !== "" ? (
              <Text
                style={{
                  fontSize: "12px",
                }}
              >
                Incident Details: {ha.injuryText}
              </Text>
            ) : null}
          </View>
        </View>
      </View>
    </Page>
  );
}

MissedMitigatorsPage.propTypes = {
  ha: PropTypes.object,
  arms: PropTypes.array,
};
